import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'toybr-cookie-hint',
    templateUrl: './cookie-hint.component.html',
    styleUrls: ['./cookie-hint.component.scss', '../../styles/styles.scss']
})
export class CookieHintComponent implements OnInit {

    private static readonly COOKIE_KEY = 'GWCALC_COOKIE_HINT';
    private static readonly COOKIE_ACCEPT = 'ACCEPT';


    @Input() show = false;


    constructor(private cookieService: CookieService) {
    }


    ngOnInit() {
        this.show = !this.isAccepted();
    }


    /**
     * Save a cookie that hides the cookie hint for 6 months
     */
    accept() {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 6);

        this.cookieService.put(CookieHintComponent.COOKIE_KEY,
            CookieHintComponent.COOKIE_ACCEPT,
            {expires: expirationDate});

        this.close();
    }

    /**
     * Close the cookie hint and show it again on the next page load
     */
    close() {
        this.show = false;
    }

    /**
     * Check if the cookie hint has been accepted
     */
    isAccepted() {
        return this.cookieService.get(CookieHintComponent.COOKIE_KEY) === CookieHintComponent.COOKIE_ACCEPT;
    }

}
