import { Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CookieOptions, CookieService } from 'ngx-cookie';

@Injectable()
export class CookieHandleService {

    constructor(public cookieService: CookieService) {
    }

    /**
     * saves cookie with given name param and duration
     * @param name cookieName
     * @param cookieValue to save as json string
     * @param durationDays how long should this cookie be valid in days
     */
    saveCookie(name: string, cookieValue: CalculationRequest, durationDays: number): void {
      const date: Date = new Date();
        date.setDate(date.getDate() + durationDays);
      const opts: CookieOptions = {
            expires: date
        };
        this.cookieService.put(name, JSON.stringify(cookieValue), opts);
        // console.log("COOKIELOG PUT: " + this.cookieService.get(name));
    }

    saveStandardCookie(name: string, content: string, durationDays: number): void {
        const date: Date = new Date();
        date.setDate(date.getDate() + durationDays);
        const opts: CookieOptions = {
          expires: date
        };
        this.cookieService.put(name, content, opts);
    }

    /**
     * Loads cookie by name and parses to CalculationRequest
     * @param name cookieName
     */
    loadCookieAsCalculationRequest(name: string): CalculationRequest {
      const req: CalculationRequest = CalculationRequest.fromData(JSON.parse(this.cookieService.get(name)));

        //reset productID, because productID can change
        req.productID = null;

        if (!req.interestRate) {
            req.interestRate = CalculationRequest.default().interestRate;
        }

        return req;
    }

    /**
     * Checks if cookie with given name is present
     * @param name cookieName
     */
    isPresent(name: string) {
      const variable = this.cookieService.get(name);
        return !(typeof variable === 'undefined' || variable === null);
    }

}
