import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../classes/requests/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  _productChanged = new EventEmitter<Product>();

  constructor() { }

  // Simple product state management with service + RxJs
  private readonly _product = new BehaviorSubject<Product>(null);
  readonly product$ = this._product.asObservable();

  // Get EventEmitters
  get productChanged(): EventEmitter<Product> {
    return this._productChanged;
  }

  get product(): Product {
    return this._product.getValue();
  }

  set product(newProduct: Product) {
    this._product.next(newProduct);
    this._productChanged.emit(newProduct);
  }
}
