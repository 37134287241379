export class CalculationRequest {

    public productID;
    public term;
    public downpayment;
    public balloonAmountRequested;
    public balloonAmount;
    public cliRequested;
    public interestRate;
    public carPrice;
    public mileage;
    public carType;

    /**
     * Factory method to create new CalculationRequest from existing data
     * @param data Already existing CalculationRequest data
     * @returns Instance of CalculationRequest with the given data
     */
    public static fromData(data): CalculationRequest {
        let req = new CalculationRequest();

        req.productID = data.productID;
        req.term = data.term;
        req.downpayment = data.downpayment;
        req.balloonAmount = data.balloonAmount;
        req.cliRequested = data.cliRequested;
        req.interestRate = data.interestRate || 3.99;
        req.carPrice = data.carPrice;
        req.balloonAmountRequested = data.balloonAmountRequested;
        req.mileage = data.mileage;
        req.carType = data.carType;

        return req;
    }

  public static clone(master: CalculationRequest): CalculationRequest {
    let req = new CalculationRequest();

    req.productID = master.productID;
    req.term = master.term;
    req.downpayment = master.downpayment;
    req.balloonAmount = master.balloonAmount;
    req.cliRequested = master.cliRequested;
    req.interestRate = master.interestRate;
    req.carPrice = master.carPrice;
    req.balloonAmountRequested = master.balloonAmountRequested;
    req.mileage = master.mileage;
    req.carType = master.carType;
    return req;
  }

    public static default(cPrice?: number, productID?: number, carType?: string): CalculationRequest {
        return CalculationRequest.fromData({
            productID: productID ? productID : null,
            interestRate: 3.99,
            balloonAmountRequested: true,
            carPrice: cPrice ? cPrice : false,
            mileage: 25000,
            carType: carType ? carType : ''
        });
    }

    public static defaultEmpty(cPrice?: number, productID?: number, carType?: string): CalculationRequest {
        return CalculationRequest.fromData({
            productID: productID,
            interestRate: 3.99,
            balloonAmountRequested: false,
            carPrice: cPrice,
            cliRequested: false,
            mileage: 25000,
            carType: carType
        });
    }

    /**
     * Bereitet die Request Daten für die Speicherung im Cookie vor.
     * - Interestrate wird nicht gespeichert
     * @param data
     * @returns
     */
    public static forCache(data: CalculationRequest): CalculationRequest {
        let req = new CalculationRequest();
        req.productID = data.productID;
        req.term = data.term;
        req.downpayment = data.downpayment;
        req.balloonAmount = data.balloonAmount;
        req.cliRequested = data.cliRequested;
        req.carPrice = data.carPrice;
        req.balloonAmountRequested = data.balloonAmountRequested;
        req.mileage = data.mileage;
        req.carType = data.carType;

        return req;
    }


    /**
     * Check if every property has any value assigned
     */
    isComplete() {
      if (this.productID == null) {
        return false;
      }

        if (this.term == null) {
            return false;
        }

        if (this.downpayment == null) {
            return false;
        }

        if (this.balloonAmountRequested == null) {
            return false;
        } else if (this.balloonAmount == null) {
            this.balloonAmount = 0;
        }

        if (this.balloonAmount == null) {
            return false;
        }

        if (this.cliRequested == null) {
            return false;
        }

        if (this.interestRate == null) {
            return false;
        }

        if (this.carPrice == null) {
            return false;
        }

        if (this.carType == null) {
          return false;
        }

        return true;
    }

    /**
     * Kopiert alle Eigenschaften in einen anderen CalculationRequest.
     * @param req Der Request, in den die Eigenschaften kopiert werden sollen
     * @returns true, falls sich beim Kopieren im Zielobjekt ein Wert geändert hat, false, falls nicht
     */
    public copyTo(req: CalculationRequest): boolean {
        let hasChanged = false;

        let properties: string[] = Object.keys(req);
        for (let i = 0; i < properties.length; i++) {
            if (req[properties[i]] !== this[properties[i]]) {
                req[properties[i]] = this[properties[i]];
                hasChanged = true;
            }
        }

        return hasChanged;
    }

    private toRawNumber(value: any, args?: any): any {
        if (value == null) {
            return undefined;
        }

        if (typeof value === 'number') {
            return value;
        }

        const comma = /,/g;
        const dot = /\./g;

        value = String(value);
        value = value
            .replace(dot, '')
            .replace(comma, '.');

        if (isNaN(value)) {
            return;
        }

        return Number(value);
    }

    unformat() {
        this.carPrice = this.toRawNumber(this.carPrice);
    }

    equals(request: CalculationRequest): boolean {

      if (!request.term
            || !request.productID
            || request.downpayment == null
            || request.balloonAmount == null
            || !request.interestRate
            || !request.carPrice
            || !request.mileage
            || !request.carType) {
            return false;
        }

        return !(this.toRawNumber(this.term) !== this.toRawNumber(request.term)
            || this.toRawNumber(this.productID) !== this.toRawNumber(request.productID)
            || this.toRawNumber(this.downpayment) !== this.toRawNumber(request.downpayment)
            || this.balloonAmountRequested !== request.balloonAmountRequested
            || this.toRawNumber(this.balloonAmount) !== this.toRawNumber(request.balloonAmount)
            || this.cliRequested !== request.cliRequested
            || this.toRawNumber(this.interestRate) !== this.toRawNumber(request.interestRate)
            || this.toRawNumber(this.carPrice) !== this.toRawNumber(request.carPrice)
            || this.toRawNumber(this.carType) !== this.toRawNumber(request.carType)
            || this.toRawNumber(this.mileage) !== this.toRawNumber(request.mileage));
    }

}
