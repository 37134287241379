import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailRequest } from '../classes/requests/email-request';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { ErrorService } from './error.service';
import { Error } from '../classes/error';
import * as SHA2 from '../classes/hash/sha2';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class EmailService {

  error: Error;

  constructor(
    public http: HttpClient,
    public errorService: ErrorService,
    public formatter: FormattedStringToNumberPipe,
    public configService: ConfigService
  ) {
  }

  /**
   * Email Service
   */
  sendMail(request: EmailRequest, calculationRequest: CalculationRequest): Observable<string> {
    return new Observable<string>(observer => {
      let params = new HttpParams();

      // budget Request
      params = params.set('sessionID', calculationRequest.sessionID.toString());
      params = params.set('customerID', calculationRequest.customerID.toString());
      params = params.set('term', calculationRequest.term.toString());
      params = params.set('instalment', this.formatter.transform(calculationRequest.instalment));
      params = params.set('downpayment', this.formatter.transform(calculationRequest.downpayment));
      params = params.set('cliRequest', String(calculationRequest.cliRequest));
      params = params.set('balloonRequest', String(calculationRequest.balloonRequest));
      params = params.set('interestRate', calculationRequest.interestRate.toString());
      params = params.set('mileage', calculationRequest.mileage.toString());

      // Email Request
      params = params.set('email', request.emailAddress);
      // Locale
      params = params.set('language', request.locale);
      params = params.set('scramble', SHA2.SHA2_256(params.get('sessionID')
        + params.get('customerID')
        + params.get('term')
        + params.get('instalment')
        + params.get('downpayment')
        + params.get('cliRequest')
        + params.get('balloonRequest')
        + params.get('interestRate')
        + params.get('mileage')
        + params.get('email')
        + this.formattedDate(new Date())));

      this.http.get(this.configService.config.urls.email, {params})
        .subscribe(
          data => {
            observer.next();
            observer.complete();
          },
          err => {
            // console.log('E-Mail ERROR ' + err);
            this.error = err.json().error;
            this.handleErrors(this.error);
            observer.error(err);
            observer.complete();
          });
    });

  }

  // No errors existing
  handleErrors(result: Error): boolean {
    if (!result) {
      this.errorService.clearErrors();
      return false;
    }

    const error: Error = new Error(result.errorCode, result.errorMessage);
    this.errorService.error(error);

    return true;
  }

  formattedDate(date: Date): string {
    const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    return days[date.getDate() - 1] + '.' + months[date.getMonth()] + '.' + date.getFullYear();
  }

}
