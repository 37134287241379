import { ModuleWithProviders, NgModule } from '@angular/core';
import { BudgetrechnerComponent } from './budgetrechner.component';
import { CalculationFormComponent } from './components/calculation-form/calculation-form.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { EmailFormComponent } from './components/email-form/email-form.component';
import { ErrorBoxComponent } from './components/error-box/error-box.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { AuthorizationService } from './services/authorization.service';
import { CalculationService } from './services/calculation.service';
import { CookieHandleService } from './services/cookie-handle.service';
import { EmailService } from './services/email.service';
import { ErrorService } from './services/error.service';
import { PdfService } from './services/pdf.service';
import { CustomDecimalPipe } from './pipes/custom-decimal.pipe';
import { FormattedStringToNumberPipe } from './pipes/formatted-string-to-number.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigService } from './services/config.service';
import { BudgetrechnerConfig } from './config/budgetrechner-config';
import { CookieModule } from 'ngx-cookie';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { JwtHelperService } from './services/jwt.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { TrackingService } from './services/tracking.service';
import { CookieHintComponent } from './components/cookie-hint/cookie-hint.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, ConfigService]
      }
    }),
    CookieModule.forRoot()
  ],
  declarations: [
    BudgetrechnerComponent,
    CalculationFormComponent,
    CurrencyInputComponent,
    EmailFormComponent,
    ErrorBoxComponent,
    TooltipComponent,
    CookieHintComponent,
    CustomDecimalPipe,
    FormattedStringToNumberPipe
  ],
  exports: [
    BudgetrechnerComponent,
    CalculationFormComponent,
    CurrencyInputComponent,
    EmailFormComponent,
    ErrorBoxComponent,
    TooltipComponent,
    CookieHintComponent,
    CustomDecimalPipe,
    FormattedStringToNumberPipe
  ]
})
export class BudgetrechnerModule {
  static forRoot(config?: BudgetrechnerConfig): ModuleWithProviders<BudgetrechnerModule> {
    return {
      ngModule: BudgetrechnerModule,
      providers: [
        {
          provide: BudgetrechnerConfig,
          useValue: config
        },
        {
          provide: ConfigService,
          deps: [BudgetrechnerConfig],
          useFactory: configServiceFactory
        },
        CalculationService,
        EmailService,
        AuthorizationService,
        ErrorService,
        CustomDecimalPipe,
        FormattedStringToNumberPipe,
        CookieHandleService,
        PdfService,
        JwtHelperService,
        TrackingService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
      ]
    };
  }
}


// <editor-fold> Exported Functions
export function configServiceFactory(config?: BudgetrechnerConfig) {
  return new ConfigService(config);
}

export function createTranslateLoader(http: HttpClient, configService: ConfigService) {
  if (!!configService.config.translateLoaderFactory) {
    let output: TranslateHttpLoader | Observable<TranslateHttpLoader> = configService.config.translateLoaderFactory(http);

    if (output instanceof Observable) {
      output.subscribe(
        (data: any) => {
          output = data;
        }
      ).unsubscribe();
    }

    return output;
  } else {
    return new TranslateHttpLoader(http, configService.config.urls.i18n + '/', '.json');
  }
}
// </editor-fold>
