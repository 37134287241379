<form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
  <div class="modal blur-off" *ngIf="!servOn">
    <span> Service steht zur Zeit nicht zur Verfügung / Service is temporarily unavailable</span>
  </div>
  <div [class.serverOffline]="!servOn">
    <div class="mainsection" myMatchHeight="rrcontainers">
      <div class="input rrcontainers input-rr-container" id="rr-input">
        <!-- Fahrzeugpreis -->
        <div class="bulk-calcform-header">
          <!-- Überschrift -->
          <h2 id="calc-title">{{"bulk-calculation-form.title" | translate}}</h2>

          <div class="input-group abstand-container">
            <div class="input-element bulk-input-element abstand-box">
              <toyrr-currency-input
                [hideDecimal]="true"
                [customStyleClass]="'bulkCalculationForm'"
                formControlName="carPrice"
                [label]="'bulk-calculation-form.financeAmount' | translate"
                [(ngModel)]="request.carPrice"
                [hasError]="!inputForm.get('carPrice').valid"
                (inputChangeReady)="onSubmit();"
                (blur)="onBlur('carPrice', $event)"
                (change)="onChange('carPrice', $event)">
              </toyrr-currency-input>
            </div>
            <div class="input-element bulk-input-element abstand-box">
              <!-- KM-Auswahl -->
              <label class="input-label">{{"calculation-form.mileage" | translate}}</label>
              <div class="select-wrapper">
                <select [(ngModel)]="request.mileage" (change)="onMileageChange($event.target.value); onSubmit(); onChange('mileage', $event.target.value);" class="input-element" formControlName="mileage">
                  <option *ngFor="let option of possibleMileage" [value]="option.value" [attr.selected]="inputForm.get('mileage').value == option.value ? true : null">
                    {{option.key}} km
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-group abstand-container">

            <div class="input-element bulk-input-element abstand-box">
              <input type="checkbox" id="mit-schlussrate" class="checkbox"
                     formControlName="balloonAmountRequested"
                     (change)="onChangeCalculateWithBalloon()"
                     [(ngModel)]="calculateWithBalloon">
              <label for="mit-schlussrate">
                <span>{{"bulk-calculation-form.includeBalloon" | translate}}&nbsp;</span>
                <toyrr-tooltip ttId="balloonAmountRequested">
                  {{"calculation-form.balloonAmountRequested.tooltip" | translate}}
                </toyrr-tooltip>
              </label>
            </div>

          </div>

        <!-- Loader -->
        <div id="loader-wrapper" [ngClass]="{ 'loaderVisibel': showCalcLoader === true }">
          <div class="loader"></div>
        </div>

          <!-- Fehlermeldungen -->
          <div id="errorbox-container" *ngIf="errorService.errors.length > 0">
              <span>
                  <toyrr-error-box [error]="errorService.latest"></toyrr-error-box>
              </span>
          </div>
        </div>

        <div class="displayAnimated" [ngClass]="{ 'showView': bulkCalculationResults.length > 0 || bulkCalculationResultsBalloon.length > 0 }">
          <!-- Überschrift -->
          <h2 id="calc-title">{{"bulk-calculation-form.selectOptions" | translate}}</h2>

          <div class="input-group abstand-container abstand-container-1">
            <div class="bulk-input-element abstand-box" *ngIf="bulkCalculationResultsBalloon.length > 0">
              <toyrr-result-select
                [headline]="'bulk-calculation-form.optionsList1' | translate"
                [headlineTooltip]="'bulk-calculation-form.optionsList1Tooltip' | translate"
                [results]="bulkCalculationResultsBalloon"
                [resultSelectGroup]="'resultsgroup1'"
                [termsArray]="possibleTerms"
                (resultSelected)="onSelectResult($event)"
              ></toyrr-result-select>
            </div>
            <div class="bulk-input-element abstand-box" *ngIf="bulkCalculationResults.length > 0">
              <toyrr-result-select
                [headline]="'bulk-calculation-form.optionsList2' | translate"
                [headlineTooltip]="'bulk-calculation-form.optionsList2Tooltip' | translate"
                [results]="bulkCalculationResults"
                [resultSelectGroup]="'resultsgroup2'"
                [termsArray]="possibleTerms"
                (resultSelected)="onSelectResult($event)"
              ></toyrr-result-select>
            </div>
          </div>

        </div>
      </div>

      <!-- Output *ngIf="selectedCalculationResponse" -->
      <toyrr-bulk-calculation-output-form [ngClass]="{ 'showView': selectedCalculationResponse, 'highlighted': resultsHighlighted==true}"
                                      [inputFormValid]="inputForm.valid"
                                     class="output output-highlight rrcontainers output-rr-container displayAnimated"
                                     id="rr-output"
                                     [loading]="showCalcLoader"
                                     (calculate)="onCalculate($event)"></toyrr-bulk-calculation-output-form>
      <!-- Only show if dealerID is present -->
      <div *ngIf="dealerId && selectedCalculationResponse" id="requestbox">
        <div *ngIf="!certificateOnly" class="requestButton" (click)="goToOnlineFinanzierung(false)"
             [ngClass]="{disabled: !inputForm.valid || errorService.errors.length !==0}">
          {{"app.request.button" | translate}}
        </div>
        <div *ngIf="certificateOnly" class="requestButton" (click)="goToOnlineFinanzierung(true)"
             [ngClass]="{disabled: !inputForm.valid || errorService.errors.length !==0}">
          {{"app.creditCheck.button" | translate}}
        </div>
      </div>

      <!-- Legal Info -->
      <div id="legal-info">
        <p id="star">*</p>
        <div>
          <span [innerHTML]="'app.legalInfo' | translate"></span><br/>
          <span *ngIf="(this.configService.config.analyticsTrackingId || this.configService.config.googleTagMangerCode)" class="text-left legal-text-left" [innerHTML]="'app.legalInfoTracking' | translate"></span>
          <a *ngIf="(this.configService.config.analyticsTrackingId || this.configService.config.googleTagMangerCode)" class="text-left legal-text-left" href="javascript:window.toyota.ratenrechner.disableTracking()">Analytics Opt-Out</a>
        </div>
      </div>
    </div>
  </div>
</form>
