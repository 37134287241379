import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { CalculationRequest } from '../../classes/requests/calculation-request';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculationService } from '../../shared/calculation.service';
import { ErrorService } from '../../shared/error.service';
import { TrackingService } from '../../shared/tracking.service';
import { TrackingEvent } from '../../classes/events/tracking-event';
import { EventType } from '../../constants/event-type';
import { CookieHandleService } from '../../shared/cookie-handle.service';
import { OfService } from '../../shared/of.service';
import { minValue } from '../../validators/min-number.validator';
import { maxValue } from '../../validators/max-number.validator';
import { FormattedStringToNumberPipe } from '../../pipes/formatted-string-to-number.pipe';
import { ChangeEvent } from '../../classes/events/change-event';
import { CalculationResponse } from '../../classes/responses/calculation-response';
import { ErrorCode } from '../../constants/error-code';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../shared/config.service';
import { ProductService } from '../../shared/product.service';
import { RatenrechnerComponent } from '../../ratenrechner.component';
import { CustomDecimalPipe } from '../../pipes/custom-decimal.pipe';

@Component({
  selector: 'toyrr-calculation-form',
  templateUrl: './calculation-form.component.html',
  styleUrls: ['./calculation-form.component.scss', '../../styles/styles.scss']
})
export class CalculationFormComponent implements OnInit, OnChanges {

  @Output() calcChange: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();
  @Output() calculate: EventEmitter<CalculationResponse> = new EventEmitter<CalculationResponse>();

  @Input() defaultRequest: CalculationRequest;
  /*@Input() priceDetectedRequest: CalculationRequest;*/
  @Input() servOn: boolean;

  @Input() dealerId: string;
  @Input() returnUrl: string;
  @Input() certificateOnly: boolean;
  @Input() isOnlineFinanzierung: boolean;

  possibleTerms: number[] = [12, 24, 36, 48, 60];
  possibleMileage: { key: string, value: number }[] = [
    {key: '10.000', value: 10000},
    {key: '15.000', value: 15000}, {key: '20.000', value: 20000}, {key: '25.000', value: 25000}, {
      key: '30.000',
      value: 30000
    }, {key: '35.000', value: 35000}, {key: '40.000', value: 40000}, {key: '45.000', value: 45000}, {
      key: '50.000',
      value: 50000
    }, {key: '55.000', value: 55000}, {key: '60.000', value: 60000}, {key: '65.000', value: 65000}, {
      key: '70.000',
      value: 70000
    }, {key: '75.000', value: 75000}, {key: '80.000', value: 80000}, {key: '85.000', value: 85000}, {
      key: '90.000',
      value: 90000
    }, {key: '95.000', value: 95000}, {key: '100.000', value: 100000}];
  readonly REGEX = '^(?:\\d+|([0-9]{1,3})(?:\\.\\d{3})+)(?:\\,\\d+)*$';
  request: CalculationRequest;
  inputForm: FormGroup;

  // Legt fest ob eine Schlussrate angegeben werden kann
  isBalloonAmountDisabled = false;

  // Legt fest, ob die Restschuldversicherung angeklickt werden kann
  // RSV ist fix deaktivert (14.05.2020)
  isCliDisabled = true;

  showCalcLoader = false;

  validation: boolean;

  constructor(
    public ratenrechnerComponent: RatenrechnerComponent,
    public calculationService: CalculationService,
    public productService: ProductService,
    public formattedStringToNumber: FormattedStringToNumberPipe,
    public formBuilder: FormBuilder,
    public errorService: ErrorService,
    public ofService: OfService,
    public trackingService: TrackingService,
    public cookieHandleService: CookieHandleService,
    public translate: TranslateService,
    public configService: ConfigService,
    public customDecimal: CustomDecimalPipe) {
  }

  ngOnInit(): void {
    this.ratenrechnerComponent._detectPrice().subscribe(
      (price) => {
        this.setupRequestOnInit(price);
      });
  }

  setupRequestOnInit(autodetectPrice: number) {
    let cookiePrice: number;
    if (this.cookieHandleService.isPresent(this.configService.config.reqCookieName)) {
      let cookieRequest: CalculationRequest;
      cookieRequest = this.cookieHandleService.loadCookieAsCalculationRequest(this.configService.config.reqCookieName);
      cookieRequest.unformat();
      cookiePrice = cookieRequest.carPrice;
    }

    // Lädt Request aus dem cookie wenn vorhanden
    // if price is detected, cookie price must be equeal. Otherwise the cookie will be ignored
    if (this.cookieHandleService.isPresent(this.configService.config.reqCookieName) && (!this.configService.config.isPriceDetected || (this.configService.config.isPriceDetected && cookiePrice == autodetectPrice))) {
      this.request = this.cookieHandleService.loadCookieAsCalculationRequest(this.configService.config.reqCookieName);
      this.calculationService.lastRequest = this.request;
      this.validation = true;
      this.configService.config.wasEmptyLoaded = false;
    } else {
      if (this.configService.config.isPriceDetected === false) {
        if (this.defaultRequest) {
          this.request = CalculationRequest.fromData(this.defaultRequest);
        } else {
          this.request = CalculationRequest.defaultEmpty();
        }
      } else {
        // price is detected
        if (this.defaultRequest) {
          this.request = CalculationRequest.fromData(this.defaultRequest);
        } else {
          this.request = CalculationRequest.defaultEmpty();
        }
        this.request.carPrice = autodetectPrice;
      }
    }

    // Initialize input form
    if (!this.inputForm) {
      this.inputForm = this.formBuilder.group({
        carPrice: [this.request.carPrice],
        downpayment: [this.request.downpayment],
        term: [this.request.term],
        // cliRequested: [this.request.cliRequested],
        // RSV ist fix deaktiviert (14.05.2020)
        cliRequested: false,
        balloonAmountRequested: [this.request.balloonAmountRequested],
        balloonAmount: [this.request.balloonAmount],
        mileage: [this.request.mileage]
      });
    }

    if (this.validation) {
      this.setupValidators();
    }

    // Setzt die angezeigt Werte bei Änderung im Service neu
    this.calculationService.requestChanged.subscribe(pRequest => {
      // updateBallonAmountValidators()
      if (!this.request === pRequest) {
        this.request = pRequest;
        this.onChange('carPrice', this.request.carPrice);
      }
    });

    this.productService.productChanged.subscribe(pProduct => {
      // overwrite default values from product (if not set in this.defaultRequest)
      if (pProduct.interestRateDefPct && (!this.defaultRequest || !this.defaultRequest.interestRate)) {
        this.request.interestRate = pProduct.interestRateDefPct;
      }

      // do not set if defaultRquest or cookie
      if (pProduct.defaultAnnualMileage && (!this.defaultRequest || !this.defaultRequest.mileage) && this.configService.config.wasEmptyLoaded) {
        this.request.mileage = pProduct.defaultAnnualMileage;
      }

      // RSV ist fix deaktiviert (14.05.2020)
      if (!pProduct.isPaymentProtectionInsurancePermitted || 1 == 1) {
        this.inputForm.get('cliRequested').disable();
        this.isCliDisabled = true;
        this.request.cliRequested = false;
      }

      if (!pProduct.isFinanceBalloonPermitted) {
        this.inputForm.get('balloonAmountRequested').disable();
        this.request.balloonAmountRequested = false;
        this.isBalloonAmountDisabled = true;
      } else if (!this.defaultRequest || !this.defaultRequest.balloonAmountRequested) {
        // only enable if not disabled in defaultRequest
        this.isBalloonAmountDisabled = false;

        // only set in request if no cookie is present
        if (!this.cookieHandleService.isPresent(this.configService.config.reqCookieName)) {
          this.request.balloonAmountRequested = true;
        }
      }

      this.validateCurrentRequest(pProduct);
      this.updatePossibleTerms(pProduct.termMin, pProduct.termMax);
      this.updatePossibleMileages(pProduct.annualMileageMin, pProduct.annualMileageMax, pProduct.annualMileageStep);

      this.onSubmit();
    });

    if (this.cookieHandleService.isPresent(this.configService.config.reqCookieName)) {
      this.onSubmit();
    }

    this.setupTracking();
  }

  // check if current request is valid (may be set from cookie and the product may have changed)
  validateCurrentRequest(pProduct) {

    if (!pProduct.isFinanceBalloonPermitted && this.request.balloonAmountRequested) {
      this.request.balloonAmountRequested = false;
      this.request.balloonAmount = 0;
    }

    if (!pProduct.isPaymentProtectionInsurancePermitted && this.request.cliRequested) {
      this.request.cliRequested = false;
    }

    if (this.request.term < pProduct.termMin) {
      this.request.term = pProduct.termMin;
    }

    if (this.request.term > pProduct.termMax) {
      this.request.term = pProduct.termMax;
    }

    if (this.request.interestRate < pProduct.interestRateMinPct) {
      this.request.interestRate = pProduct.interestRateMinPct;
    }

    if (this.request.interestRate > pProduct.interestRateMaxPct) {
      this.request.interestRate = pProduct.interestRateMaxPct;
    }

  }

  updatePossibleMileages(minValue: number, maxValue: number, steps: number) {
    const possibleMileage: { key: string, value: number }[] = [];
    let lastMileage = minValue;

    while (lastMileage <= maxValue) {
      possibleMileage.push({
        key: this.customDecimal.transform(Number(lastMileage), 0, ',', '.', ''),
        value: lastMileage
      });
      lastMileage = lastMileage + steps;
    }
    this.possibleMileage = possibleMileage;
  }

  updatePossibleTerms(minValue: number, maxValue: number) {
    const newPossibleTerms: number[] = [];

    for (const term of this.possibleTerms) {
      if (minValue <= Number(term) && Number(term) <= maxValue) {
        newPossibleTerms.push(Number(term));
      }
    }

    this.possibleTerms = newPossibleTerms;
  }

  /**
   * NUR, wenn ein Preis auf der Seite gesucht wird. TOYLIB-122
   * Wenn auf der Seite ein Preis erkannt wurder, setze die Werte in der Form, nachdem die Berechnung mit den Werten durchgeführt wurde
   * @param changes holding the current changes
   */
  ngOnChanges(changes: SimpleChange | any): void {

  }


  setupValidators() {
    this.inputForm.get('carPrice').setValidators([
      Validators.required,
      Validators.pattern(this.REGEX),
      minValue(this.minCarPrice),
      maxValue(this.maxCarPrice)
    ]);


    this.inputForm.get('downpayment').setValidators([
      Validators.required,
      Validators.pattern(this.REGEX)
    ]);


    this.inputForm.get('term').setValidators([
      Validators.required
    ]);
  }

  /**
   * Stößt die Berechnung mit den eingegeben Werten an
   */
  onSubmit() {
    if (!this.productService.product || !this.request.carPrice) {
      return;
    }

    this.request.productID = this.productService.product.productID;
    this.request.carType = this.productService.product.carType;

    // getDefaultValues
    if (!this.request.isComplete() && this.request.carPrice != null) {
      // Convert strings to number by hand because validation is disabled here
      this.request.unformat();
      this.showCalcLoader = true;
      this.calculationService.getDefaultValues(this.request).subscribe((data) => {

        this.showCalcLoader = false;
        this.validation = true;


        /*Leer geladene Maske, nur Fahrzeugpreis wurde eingegeben => Berechnng mit defaultValues soll starten*/
        // if(this.configService.config.wasEmptyLoaded){

        this.validation = true;
        this.setupValidators();

        /* Daten aus Parametern in Default Request übernehmen */
        if (!this.request.term) {
          this.request.term = data.term;
        }

        // set interestRate from default response
        this.request.interestRate = data.interestRateEffective;

        // Anzahlung nur übernehmen, falls Feld vorhanden
        if (!this.request.downpayment) {
          this.request.downpayment = data.downpayment;
        }

        // Schlussrate nur übernehmen, falls Feld aktiv
        if (this.request.balloonAmountRequested) {
          if (!this.request.balloonAmount) {
            this.request.balloonAmount = data.balloonAmount;
          }
        } else
          this.request.balloonAmount = 0;


        this.request.cliRequested = data.cliRequested;

        this.getCalcResult(false);
        //}

      });
      return;
    }

    this.getCalcResult(false);
  }

  public forceRecalculation() {
    this.getCalcResult(true);
  }

  private getCalcResult(forceRecalculation: boolean): void {

    if (!this.inputForm.valid) {
      this.validate();
      return;
    }


    if (!this.request.equals(this.calculationService.lastSubmittedRequest) || forceRecalculation) {
      this.showCalcLoader = true;
      this.request.unformat();
      if (this.request.balloonAmountRequested) {
        this.calculationService.getCalculationResultWithBalloonAmount(this.request)
          .subscribe(
            (response) => {
              this.showCalcLoader = false;
              this.validation = true;
            }
          );
      } else {
        this.calculationService.getCalculationResult(this.request)
          .subscribe(
            (response) => {
              this.showCalcLoader = false;
              this.validation = true;
              /*this.calculationService.lastResponse = response;*/
            }
          );
      }
      this.calculationService.lastSubmittedRequest = CalculationRequest.fromData(this.request);
    }
  }

  /**
   * Setzt die Laufzeit auf einen angegebenen Wert.
   * Wird ausgeführt, wenn die ausgewählte Laufzeit sich ändert.
   * @param term Laufzeit in Monaten
   */
  onTermChange(term: number) {
    this.request.term = term;
    this.onChange('term', term);

    this.onSubmit();
  }

  /**
   * Setzt den Minimalwert im Validator für die Schlussrate
   * und die Schlussrate bei Fahrzeugpreiswechsel
   */
  onCarPriceChange() {
  }

  /**
   * Schaltet den Status der Schlussrate um.
   * Wird die Schlussrate deaktiviert, wird sie auf 0 gesetzt.
   * Wird aufgerufen, wenn die Schlussrate aktiviert bzw. deaktiviert wird.
   */
  onChangeBalloonAmountRequested() {
    // toggle
    if (!this.request.balloonAmountRequested) {
      this.request.balloonAmount = 0;
    }

    if (this.inputForm.valid) {
      this.onSubmit();
    }
  }


  /**
   * Setzt die Kilometerleistung auf den gewählten Wert.
   * @param newValue Kilometerleistung
   */
  onMileageChange(newValue: any) {
    this.request.mileage = newValue;
  }

  /**
   * Öffnet die Onlinefinanzierung inkl. Parameter im neuen Tab
   */
  goToOnlineFinanzierung(isCreditCheckCertificateEntry: boolean) {
    // Tracking
    this.trackingService.printButtonEmitter.emit(new TrackingEvent(EventType.CLICK_OF));

    let url = this.ofService.generateOfUrl(
      this.calculationService.lastRequest,
      this.dealerId,
      this.returnUrl,
      isCreditCheckCertificateEntry,
      false
    );

    //Google Tag Manager (if js variable 'appendToUri' is set, add it to URL
    if (window['appendToUri'])
      url = url + window['appendToUri'];
    window.open(url, '_blank');
  }

  setupTracking(): void {
    for (const control in this.inputForm.controls) {
      this.inputForm.controls[control].valueChanges.subscribe(
        (data) => {
          this.trackingService.valueChangeEmitter.emit(
            new TrackingEvent(EventType.VALUE_CHANGE, {id: control, value: data})
          );
        }
      );
    }
  }

  onChange(key: string, value: any): void {
    // Handle errors
    this.validation = true;
    //this.setupValidators();
    this._handleErrors(key);

    // decide if change event should be fired
    if (!this.request.equals(this.calculationService.lastSubmittedRequest)) {
      this.calcChange.emit({key, value});
    }
  }

  onBlur(key: string, value: any) {
    this.onChange(key, value);
  }

  onCalculate(response: CalculationResponse): void {
    this.calculate.emit(response);
  }

  _handleErrors(key: string) {
    switch (key) {
      case 'carPrice': {

        // Invalid pattern
        if (this.inputForm.get('carPrice').hasError('pattern')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_INVALID_PATTERN,
            errorMessage: this.translate.instant('calculation-form.invalidValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_INVALID_PATTERN]);
        }

        // Less than minimum value
        if (this.inputForm.get('carPrice').hasError('minValue')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM,
            errorMessage: this.translate.instant('calculation-form.lessThanMinValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM]);
        }

        // Exceeded max value
        if (this.inputForm.get('carPrice').hasError('maxValue')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE,
            errorMessage: this.translate.instant('calculation-form.exceedMaxValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE]);
        }

        return;
      }
      case 'downpayment': {
        // Invalid downpayment
        if (!this.inputForm.get('downpayment').valid) {
          /*console.log('Downpayment is invalid');*/
          this.errorService.error({
            errorCode: ErrorCode.DOWNPAYMENT_INVALID,
            errorMessage: this.translate.instant('calculation-form.invalidValue')
          });
          return;
        }

        this.errorService.okAll([ErrorCode.DOWNPAYMENT_INVALID]);
      }
    }
  }

  // Entscheidet, ob die Tabelle angezeigt werden soll oder nicht
  hideElementActive(): boolean {
    // !this.dealerId || this.inputForm.invalid || this.errorService.errors.length > 0

    if (this.inputForm.valid && this.configService.config.isPriceDetected
      && !this.configService.config.tableAlreadyLoaded && this.configService.config.wasEmptyLoaded) {
      return false;
    } else if ((!this.configService.config.tableAlreadyLoaded && this.configService.config.wasEmptyLoaded
      && (!this.configService.config.isPriceDetected || (this.inputForm.invalid)))) {
      return true;
    } else {
      if (!this.configService.config.tableAlreadyLoaded) {
        this.configService.config.tableAlreadyLoaded = true;
      }
      return false;
    }
  }

  validate() {
    this._handleErrors('carPrice');
    this._handleErrors('downpayment');
  }

  validateCarPrice() {
    this._handleErrors('carPrice');
  }


  get minCarPrice() {
    return this.configService.config.minCarPrice || 2500;
  }

  get maxCarPrice() {
    return this.configService.config.maxCarPrice || 50000;
  }

}
