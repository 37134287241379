import { Injectable } from '@angular/core';
import { interval, Observable, of, throwError } from 'rxjs';
import { ErrorService } from './error.service';
import { Error } from '../classes/error';
import { LoginResponse } from '../classes/responses/login-response';
import { BudgetrechnerComponent } from '../budgetrechner.component';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from './jwt.service';
import { flatMap, map, retryWhen } from 'rxjs/operators';

const TOKEN_EXP_OFFSET = 10;

@Injectable()
export class AuthorizationService {

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private errorService: ErrorService,
        private configService: ConfigService,
        public jwtHelper: JwtHelperService
    ) {
    }


    // Authorization

    /**
     * Führt die Autorisierung am Serviceprovider durch.
     */
    authorize(): Observable<LoginResponse> {
        return new Observable<LoginResponse>(observer => {
            const body = {
                originUrl: window.location.toString()
            };

            // this.http.post('http://127.0.0.1:8080/auth/anonymous',
            this.http.post(this.configService.config.urls.auth, body)
                .pipe(
                    map(res => new LoginResponse(res)),
                    retryWhen(_ => {
                        BudgetrechnerComponent.setServerOnline(false);
                        console.log('Verbindung zum Server gescheitert. Neuer Versuch in 10 Sekunden');

                        return interval(5000).pipe(
                            flatMap(count => count === 5 ? throwError('Giving up') : of(count))
                        );
                    })
                ).subscribe(
                (data: LoginResponse) => {
                    if (this.handleErrors(data)) {
                        observer.error(data);
                    } else {
                        BudgetrechnerComponent.setServerOnline(true);
                        // this.translate.reloadLang(BudgetrechnerComponent.getLanguage());
                        this.translate.use(BudgetrechnerComponent.getLanguage());
                        localStorage.setItem('token', data.token);
                        observer.next(data);
                        observer.complete();
                    }
                },
                (err) => {
                    this.errorService.error(this.errorService.connectionError());
                    observer.error();
                }
            );
        });
    }

    isTokenValid(): boolean {
        return !this.jwtHelper.isTokenExpired(this.token, TOKEN_EXP_OFFSET);
    }

    decodeToken(jwt: string): any {
        return this.jwtHelper.decodeToken(jwt);
    }


    // Error Handling

    /**
     * Prüft ob ein Fehler beim Login aufgetreten ist.
     * @param result Das Login-Ergebnis, das vom Serviceprovider kommt
     * @returns true, falls Fehler aufgetreten sind, false, falls nicht
     */
    handleErrors(result: LoginResponse): boolean {
        // No errors existing
        if (!result.error || !result.error.errorCode) {
            this.errorService.clearErrors();
            return false;
        }

        const error = new Error(result.error.errorCode, result.error.errorMessage);
        this.errorService.error(error);

        return true;
    }


    get token() {
        return localStorage.getItem('token');
    }

    set token(token: string) {
        localStorage.setItem('token', token);
    }

    get authorizationHeader() {
        return 'Bearer ' + this.token;
    }

}
