import { BudgetrechnerUrlConfig } from './budgetrechner-url-config';

export class BudgetrechnerConfig {

    defaultLanguage?: string;
    urls?: BudgetrechnerUrlConfig;
    translateLoaderFactory?: Function;
    cookieName?: string;
    econdaSiteId?: string;
    analyticsTrackingId?: string;
    analyticsTrackerName?: string;

}
