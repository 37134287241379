import { Injectable } from '@angular/core';
import { RatenrechnerConfig } from '../config/ratenrechner-config';

@Injectable()
export class ConfigService {

  _config: RatenrechnerConfig;

  constructor(customConfig?: RatenrechnerConfig) {
    this.config = {
      defaultLanguage: 'de',
      urls: {
        // i18n: 'https://strapi.toyota.itsd-consulting.services/translations?language=DE&_limit=1000',
        i18n: 'http://calc-stage.itsd-consulting.com/language',
        auth: 'http://calc-stage.itsd-consulting.com/auth/anonymous',
        initialCalculation: 'http://calc-stage.itsd-consulting.com/api/v2/calculateInitial',
        bulkCalculation: 'http://calc-stage.itsd-consulting.com/api/v2/calculateBulk',
        calculation: 'http://calc-stage.itsd-consulting.com/api/v2/calculate',
        email: 'http://calc-stage.itsd-consulting.com/api/v1/sendCalculationMail',
        pdf: 'http://calc-stage.itsd-consulting.com/pdf',
        of: 'https://toyota-bank-portal.de/of/',
        products: 'http://calc-stage.itsd-consulting.com/api/v2/products',
        calculateBalloon: 'http://calc-stage.itsd-consulting.com/api/v2/calculateBalloon'
      },
      productServiceFilter: 'itsdCalculateLoan',
      analyticsTrackingId: '',
      analyticsTrackerName: '',
      googleTagMangerCode: '',
      minCarPrice: 2500,
      maxCarPrice: 50000,
      isPriceDetected: false,
      wasEmptyLoaded: false,
      tableAlreadyLoaded: false,
      reqCookieName: 'rr_req',
      bulkReqCookieName: 'rr_req_bulk',
      reqCookieDuration: 14,
      bulkReqCookieDuration: 14

    };

    if (customConfig != null) {
      this.config = customConfig;
    }

    // console.log('LOADED CONFIG: ' + JSON.stringify(this.config));
  }

  get config() {
    return this._config;
  }

  set config(config: RatenrechnerConfig) {
    if (!config) {
      return;
    }

    if (!this._config) {
      this._config = config;
    }

    Object.keys(config).forEach((key: string) => {
      // Nicht gesetzte Werte nicht übernehmen
      if (config[key] == null) {
        return;
      }

      this._config[key] = config[key];
    });
  }

}
