import { Component, Input } from '@angular/core';
import { TrackingService } from '../../services/tracking.service';
import { EventType } from '../../constants/event-type';
import { TrackingEvent } from '../../classes/events/tracking-event';

@Component({
    selector: 'toybr-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss', '../../styles/styles.scss']
})
export class TooltipComponent {

    @Input() ttId: string;
    tooltipCursorOffset = 10;
    show: boolean;
    posX: number;
    posY: number;
    top = 'auto';
    right = 'auto';
    bottom = 'auto';
    left = 'auto';
    width = '300px';
    height = '0px';


    constructor(public trackingService: TrackingService) {
    }


    onMouseEnter(event: any): void {
        const clientRect = event.target.getBoundingClientRect();

        this.posX = clientRect.left;
        this.posY = clientRect.top;

        this.calcTopAndBottom();
        this.calcLeftAndRight();

        this.show = true;

        // Tracking
        this.trackingService.trackToolTipShow(
            new TrackingEvent(EventType.TOOLTIP_HIDE, this.ttId)
        );
    }

    onMouseLeave(event: any): void {
        this.show = false;
        this.width = '0px';
        this.height = '0px;';

        // Tracking
        this.trackingService.trackToolTipHide(
            new TrackingEvent(EventType.TOOLTIP_HIDE, this.ttId)
        );
    }

    onClick(): void {
        this.show = !this.show;

        // Tracking
        if (this.show) {
            this.trackingService.trackToolTipShow(
                new TrackingEvent(EventType.TOOLTIP_HIDE, this.ttId)
            );
        } else {
            this.trackingService.trackToolTipHide(
                new TrackingEvent(EventType.TOOLTIP_HIDE, this.ttId)
            );
        }
    }


    calcTopAndBottom(): void {
        const height: number = document.documentElement.clientHeight || document.body.clientHeight;
        this.height = 'inherit';

        if (this.posY > height / 2) {
            this.top = 'auto';
            this.bottom = this.tooltipCursorOffset + 'px';
        } else {
            this.bottom = 'auto';
            this.top = this.tooltipCursorOffset + 'px';
        }
    }

    calcLeftAndRight(): void {
        const width: number = document.documentElement.clientWidth || document.body.clientWidth;
        this.width = width / 2 + 'px';

        if (this.posX > width / 2) {
            this.left = 'auto';
            this.right = this.tooltipCursorOffset + 'px';
        } else {
            this.right = 'auto';
            this.left = this.tooltipCursorOffset + 'px';
        }
    }

}
