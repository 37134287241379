import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CalculationRequest } from '../../classes/requests/calculation-request';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculationService } from '../../shared/calculation.service';
import { ErrorService } from '../../shared/error.service';
import { TrackingService } from '../../shared/tracking.service';
import { TrackingEvent } from '../../classes/events/tracking-event';
import { EventType } from '../../constants/event-type';
import { CookieHandleService } from '../../shared/cookie-handle.service';
import { OfService } from '../../shared/of.service';
import { minValue } from '../../validators/min-number.validator';
import { maxValue } from '../../validators/max-number.validator';
import { FormattedStringToNumberPipe } from '../../pipes/formatted-string-to-number.pipe';
import { ChangeEvent } from '../../classes/events/change-event';
import { CalculationResponse } from '../../classes/responses/calculation-response';
import { ErrorCode } from '../../constants/error-code';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../shared/config.service';
import { ProductService } from '../../shared/product.service';
import { BulkCalculationResponse } from '../../classes/responses/bulk-calculation-response';
import { CustomDecimalPipe } from '../../pipes/custom-decimal.pipe';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'toyrr-bulk-calculation-form',
  templateUrl: './bulk-calculation-form.component.html',
  styleUrls: ['./bulk-calculation-form.component.scss', '../../styles/styles.scss']
})
export class BulkCalculationFormComponent implements OnInit{

  @Output() calcChange: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();
  @Output() calculate: EventEmitter<CalculationResponse> = new EventEmitter<CalculationResponse>();

  @Input() defaultRequest: CalculationRequest;
  @Input() servOn: boolean;

  @Input() dealerId: string;
  @Input() returnUrl: string;
  @Input() certificateOnly: boolean;
  @Input() isOnlineFinanzierung: boolean;

  possibleTerms:number[] = [12, 24, 36, 48, 60];
  possibleMileage: { key: string, value: number }[] = [
    {key: '10.000', value: 10000},
    {key: '15.000', value: 15000}, {key: '20.000', value: 20000}, {key: '25.000', value: 25000}, {
      key: '30.000',
      value: 30000
    }, {key: '35.000', value: 35000}, {key: '40.000', value: 40000}, {key: '45.000', value: 45000}, {
      key: '50.000',
      value: 50000
    }, {key: '55.000', value: 55000}, {key: '60.000', value: 60000}, {key: '65.000', value: 65000}, {
      key: '70.000',
      value: 70000
    }, {key: '75.000', value: 75000}, {key: '80.000', value: 80000}, {key: '85.000', value: 85000}, {
      key: '90.000',
      value: 90000
    }, {key: '95.000', value: 95000}, {key: '100.000', value: 100000}];
  readonly REGEX = '^(?:\\d+|([0-9]{1,3})(?:\\.\\d{3})+)(?:\\,\\d+)*$';
  request: CalculationRequest;
  inputForm: FormGroup;

  // Legt fest ob eine Schlussrate angegeben werden kann
  isBalloonAmountDisabled = false;
  showCalcLoader = false;
  resultsHighlighted = false;
  highlightedTimer;

  validation: boolean;
  selectedCalculationResponse: CalculationResponse;
  calculateWithBalloon: boolean;

  bulkCookieSelectedTerm: number;
  bulkCookieSelectedBalloon: boolean;

  bulkCalculationResultsBalloon: CalculationResponse[];
  bulkCalculationResults: CalculationResponse[];

  constructor(
    @Inject(DOCUMENT) document,
    public calculationService: CalculationService,
    public productService: ProductService,
    public formattedStringToNumber: FormattedStringToNumberPipe,
    public formBuilder: FormBuilder,
    public errorService: ErrorService,
    public ofService: OfService,
    public trackingService: TrackingService,
    public cookieHandleService: CookieHandleService,
    public translate: TranslateService,
    public configService: ConfigService,
    public customDecimal: CustomDecimalPipe) {

    this.calculateWithBalloon = true;
    this.selectedCalculationResponse = null;
  }

  ngOnInit(): void {

    this.productService.productChanged.subscribe(pProduct => {
      //console.log("### overwrite default values from product");
      //console.log(pProduct);
      //overwrite default values from product
      if(pProduct.interestRateDefPct && (!this.defaultRequest || !this.defaultRequest.interestRate))
        this.request.interestRate = pProduct.interestRateDefPct;
      if(pProduct.defaultAnnualMileage
        && (!this.defaultRequest || !this.defaultRequest.mileage)
        && (!this.cookieHandleService.isPresent(this.configService.config.bulkReqCookieName) || !this.cookieHandleService.loadCookieAsCalculationRequest(this.configService.config.bulkReqCookieName).mileage))
        this.request.mileage = pProduct.defaultAnnualMileage;

      this.updatePossibleTerms(pProduct.termMin, pProduct.termMax);
      this.updatePossibleMileages(pProduct.annualMileageMin, pProduct.annualMileageMax, pProduct.annualMileageStep);

      if(this.defaultRequest || this.cookieHandleService.isPresent(this.configService.config.bulkReqCookieName))
        this.onSubmit();
    });


    // Lädt Request aus dem cookie wenn vorhanden

    if (this.cookieHandleService.isPresent(this.configService.config.bulkReqCookieName)) {
      let bulkCookieRequest = this.cookieHandleService.loadCookieAsCalculationRequest(this.configService.config.bulkReqCookieName);

      this.request = CalculationRequest.defaultEmpty();
      this.request.carPrice = bulkCookieRequest.carPrice;
      this.request.mileage = bulkCookieRequest.mileage;
      this.bulkCookieSelectedTerm = bulkCookieRequest.term;
      this.bulkCookieSelectedBalloon = bulkCookieRequest.balloonAmount && bulkCookieRequest.balloonAmount > 0;
      this.calculateWithBalloon = bulkCookieRequest.balloonAmountRequested;
    } else {
      if (this.defaultRequest) {
        this.request = CalculationRequest.fromData(this.defaultRequest);
      } else {
        this.request = CalculationRequest.defaultEmpty();
      }

      this.isBalloonAmountDisabled = !this.request.balloonAmountRequested;
    }

    // Initialize input form
    this.inputForm = this.formBuilder.group({
      carPrice: [this.request.carPrice],
      downpayment: [this.request.downpayment],
      term: [this.request.term],
      cliRequested: [this.request.cliRequested],
      balloonAmountRequested: [this.request.balloonAmountRequested],
      balloonAmount: [this.request.balloonAmount],
      mileage: [this.request.mileage]
    });

    this.setupValidators();


    /*
    // Setzt die angezeigt Werte bei Änderung im Service neu
    this.calculationService.requestChanged.subscribe(pRequest => {

      console.log("++++++++++ requestChanged triggered");

      if (!this.request === pRequest) {
        this.request = pRequest;
        this.isBalloonAmountDisabled = !this.request.balloonAmountRequested;
        this.onChange('carPrice', this.request.carPrice);
      }
    });
    */

    this.setupTracking();

    this.bulkCalculationResults = new Array();
    this.bulkCalculationResultsBalloon = new Array();
  }

  updatePossibleMileages(minValue: number, maxValue: number, steps: number){
    let possibleMileage: { key: string, value: number }[] = [];
    let lastMileage = minValue;

    while (lastMileage <= maxValue){
      possibleMileage.push({key: this.customDecimal.transform(Number(lastMileage), 0, ',', '.', ''), value: lastMileage});
      lastMileage = lastMileage + steps;
    }
    this.possibleMileage = possibleMileage;
  }

  updatePossibleTerms(minValue: number, maxValue: number){
    let newPossibleTerms:number[] = [];

    for (let term of this.possibleTerms) {
      if(minValue <= Number(term) && Number(term) <= maxValue)
        newPossibleTerms.push(Number(term));
    }

    this.possibleTerms = newPossibleTerms;
  }

  setupValidators() {
    this.inputForm.get('carPrice').setValidators([
      Validators.required,
      Validators.pattern(this.REGEX),
      minValue(this.minCarPrice),
      maxValue(this.maxCarPrice)
    ]);
  }

  /**
   * Stößt die Berechnung mit den eingegeben Werten an
   */
  onSubmit() {

    if (!this.productService.product
      || !this.request.carPrice) {
      return;
    }

    if(!this.inputForm.valid){
      return;
    }

    this.request.unformat();

    this.request.productID = this.productService.product.productID;
    this.request.carType = this.productService.product.carType;

    // getDefaultValues
    if (!this.request.isComplete() && this.request.carPrice != null) {

      // Convert strings to number by hand because validation is disabled here
      this.request.unformat();
      this.showCalcLoader = true;

      this.calculationService.getDefaultValues(this.request).subscribe((data) => {
        this.showCalcLoader = false;

        this.request.interestRate = data.interestRateEffective;

        /*Leer geladene Maske, nur Fahrzeugpreis wurde eingegeben => Berechnung mit defaultValues soll starten*/
        //set needed values
        this.request.term = this.possibleTerms[0];
        this.request.downpayment = 0;
        this.request.balloonAmount = 0;
        this.request.balloonAmountRequested = this.calculateWithBalloon;

        this.getCalcResult(false);

      });
      return;
    }

    this.getCalcResult(false);
  }

  public forceRecalculation(){
    this.getCalcResult(true);
  }

  private getCalcResult(forceRecalculation: boolean): void {

    if(!this.inputForm.valid){
      this.validate();
      return;
    }

    if (!this.request.equals(this.calculationService.lastSubmittedRequest) || forceRecalculation) {

      this.selectedCalculationResponse = null;
      this.showCalcLoader = true;

      /*Prepare array of requests */
    let bulkRequest: CalculationRequest[] = new Array();

      if(this.calculateWithBalloon){
        //prepare requests with balloon
        bulkRequest = this.prepareRequests(true);
      }

      //prepare requests without balloon
      bulkRequest = bulkRequest.concat(this.prepareRequests(false));

      this.calculationService.getBulkCalculationResults(bulkRequest)
        .subscribe(
        (bulkResponse: BulkCalculationResponse) => {

          this.bulkCalculationResults = new Array();
          this.bulkCalculationResultsBalloon = new Array();

          //Ergebnisse einer der beiden Listen hinzufügen
          for(let result of bulkResponse.calculationResults){
            if(result.balloonAmount <= 0)
              this.bulkCalculationResults.push(result);
            else
              this.bulkCalculationResultsBalloon.push(result);
          }

          /* this.onCalculate(calcRes); */
          this.showCalcLoader = false;


          setTimeout(()=>{
            this.setPreselectedResult();
          }, 500);
        }
      );
      //FIXME check
      this.calculationService.lastRequest = CalculationRequest.fromData(this.request);
      this.calculationService.lastSubmittedRequest = CalculationRequest.fromData(this.request);
    }
  }

  private prepareRequests(withBallon: boolean): CalculationRequest[]{

    let bulkRequest: CalculationRequest[] = new Array();

    let requestTemplate: CalculationRequest  = CalculationRequest.clone(this.request);

    if(withBallon)
      requestTemplate.balloonAmount = null;
    else
      requestTemplate.balloonAmount = 0;

    requestTemplate.unformat();

    for(let term of this.possibleTerms){
      let singleRequest = CalculationRequest.clone(requestTemplate);
      singleRequest.term = term;
      bulkRequest.push(singleRequest);
    }

    return bulkRequest;
  }

  onChangeCalculateWithBalloon(){
    this.request.balloonAmountRequested = this.calculateWithBalloon;
    this.onSubmit();
  }

  onSelectResult(result: any){
    this.handleAnimation();

    //if max balloon was calculated by service, set this balloon in request (for E-Mail and PDF creation)
    if(result.selectedResult.balloonAmount && result.selectedResult.balloonAmount > 0) {
      this.calculationService.lastRequest.balloonAmount = result.selectedResult.balloonAmount;
      this.calculationService.lastSubmittedRequest.balloonAmount = result.selectedResult.balloonAmount;
      this.request.balloonAmount = result.selectedResult.balloonAmount;
    }else{
      this.calculationService.lastRequest.balloonAmount = 0;
      this.calculationService.lastSubmittedRequest.balloonAmount = 0;
      this.request.balloonAmount = 0;
    }

    this.calculationService.lastRequest.productID = this.request.productID;

    //set selected term request and last request
    this.calculationService.lastRequest.term = this.possibleTerms[result.index];
    this.calculationService.lastSubmittedRequest.term = this.possibleTerms[result.index];

    this.calculationService.lastRequest.mileage = this.request.mileage;
    this.calculationService.lastSubmittedRequest.mileage = this.request.mileage;

    //fire event emiter with set method
    this.calculationService.lastRequest = this.calculationService.lastRequest;
    this.request.term = this.possibleTerms[result.index];
    this.calculationService.lastResponse = result.selectedResult;
    this.selectedCalculationResponse = result.selectedResult;

    //save selection in cookie
    this.cookieHandleService.saveCookie(this.configService.config.bulkReqCookieName, CalculationRequest.forCache(this.request), this.configService.config.bulkReqCookieDuration);
  }

  setPreselectedResult(){
    let resultId = "resultsgroup";

    //set only if bulkCookieSelectedTerm is not null
    if(this.bulkCookieSelectedTerm){
      if(this.bulkCookieSelectedBalloon)
        resultId += "1";
      else
        resultId += "2";

      let index = this.possibleTerms.indexOf(this.bulkCookieSelectedTerm);
      if(index >= 0){
        resultId += index;
      }

      resultId += "label";
      this.bulkCookieSelectedTerm = null;

      if(document.getElementById(resultId))
        document.getElementById(resultId).click();

    }
  }

  handleAnimation() {
    clearTimeout(this.highlightedTimer);
    let scope = this;
    scope.resultsHighlighted = true;

    this.highlightedTimer = setTimeout (function(){
      scope.resultsHighlighted = false;
    }, 1000);
  }


  /**
   * Setzt die Kilometerleistung auf den gewählten Wert.
   * @param newValue Kilometerleistung
   */
  onMileageChange(newValue: any) {
    this.request.mileage = newValue;
  }

  /**
   * Öffnet die Onlinefinanzierung inkl. Parameter im neuen Tab
   */
  goToOnlineFinanzierung(isCreditCheckCertificateEntry: boolean) {
    // Tracking
    this.trackingService.printButtonEmitter.emit(new TrackingEvent(EventType.CLICK_OF));

    let url = this.ofService.generateOfUrl(
      this.calculationService.lastRequest,
      this.dealerId,
      this.returnUrl,
      isCreditCheckCertificateEntry,
      true
    );

    //Google Tag Manager (if js variable "appendToUri" is set, add it to URL
    if(window['appendToUri'])
      url = url + window['appendToUri'];
    window.open(url, '_blank');
  }

  setupTracking(): void {
    for (const control in this.inputForm.controls) {
      this.inputForm.controls[control].valueChanges.subscribe(
        (data) => {
          this.trackingService.valueChangeEmitter.emit(
            new TrackingEvent(EventType.VALUE_CHANGE, {id: control, value: data})
          );
        }
      );
    }
  }

  onChange(key: string, value: any): void {
    // Handle errors
    //this.setupValidators();
    //this._handleErrors(key);

    //always handle carPrice errors, because all other input elements cant't be invalid
    this._handleErrors("carPrice");

    // decide if change event should be fired
    if (!this.request.equals(this.calculationService.lastSubmittedRequest)) {
      this.calcChange.emit({key, value});
    }
  }

  onBlur(key: string, value: any) {
    this.onChange(key, value);
  }

  onCalculate(response: CalculationResponse): void {
    this.calculate.emit(response);
  }

  _handleErrors(key: string) {
    switch (key) {
      case 'carPrice': {
        // Invalid pattern
        if (this.inputForm.get('carPrice').hasError('pattern')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_INVALID_PATTERN,
            errorMessage: this.translate.instant('calculation-form.invalidValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_INVALID_PATTERN]);
        }

        // Less than minimum value
        if (this.inputForm.get('carPrice').hasError('minValue')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM,
            errorMessage: this.translate.instant('calculation-form.lessThanMinValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM]);
        }

        // Exceeded max value
        if (this.inputForm.get('carPrice').hasError('maxValue')) {
          this.errorService.error({
            errorCode: ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE,
            errorMessage: this.translate.instant('calculation-form.exceedMaxValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE]);
        }

        // Handle balloon amount errors
        this._handleErrors('balloonAmount');

        return;
      }
      case 'balloonAmount': {

        // Invalid balloon amount
        if (!this.isBalloonAmountDisabled
          && this.inputForm.get('balloonAmount').hasError('minValue')) {
          this.errorService.error({
            errorCode: ErrorCode.BALLOON_AMOUNT_INVALID,
            errorMessage: this.translate.instant('calculation-form.invalidMinBalloonAmount')
          });
        } else if (!this.inputForm.get('balloonAmount').valid
          && !this.isBalloonAmountDisabled
          && !this.inputForm.get('balloonAmount').hasError('minValue')) {
          this.errorService.error({
            errorCode: ErrorCode.BALLOON_AMOUNT_INVALID,
            errorMessage: this.translate.instant('calculation-form.invalidValue')
          });
        } else {
          this.errorService.okAll([ErrorCode.BALLOON_AMOUNT_INVALID]);
        }
        return;
      }
      case 'downpayment': {
        // Invalid downpayment
        if (!this.inputForm.get('downpayment').valid) {
          /*console.log('Downpayment is invalid');*/
          this.errorService.error({
            errorCode: ErrorCode.DOWNPAYMENT_INVALID,
            errorMessage: this.translate.instant('calculation-form.invalidValue')
          });
          return;
        }

        this.errorService.okAll([ErrorCode.DOWNPAYMENT_INVALID]);
      }
    }
  }

  validate() {
    this._handleErrors('carPrice');
  }

  validateCarPrice() {
    this._handleErrors('carPrice');
  }


  get minCarPrice() {
    return this.configService.config.minCarPrice || 2500;
  }

  get maxCarPrice() {
    return this.configService.config.maxCarPrice || 50000;
  }

}
