<!-- Überschrift -->
<div class="flex-container">
    <h2 class="output-title">{{"bulk-calculation-form.selectedOption" | translate}}</h2>
</div>

<!-- E-Mail Form -->
<toyrr-email-form [calculationRequest]="calculationRequest" (cancel)="showEmailDialog = false"
                  *ngIf="showEmailDialog && !(!inputFormValid || errorService.errors.length !==0)"></toyrr-email-form>

<hr noshade class="no-margin-top">

<div class="calc-output">

    <!-- Calculation Output -->
    <div [class.blur-on]="loading" [class.blur-off]="!loading">

        <!-- Netto Darlehensbetrag -->
        <div class="flex-container">
            <label>{{"app.netFinanceAmount" | translate}}
              <toyrr-tooltip ttId="netFinanceAmount">{{"app.netFinanceAmount.tooltip" | translate}}</toyrr-tooltip>
            </label>
            <span class="output-right-aligned">{{calculationResponse.netFinanceAmount | customDecimal:2:',':'.'}} €</span>
        </div>

        <!-- Laufzeit -->
        <div *ngIf="!!calculationRequest.term" class="flex-container">
          <label>{{"bulk-calculation-form.term" | translate}}</label>
          <span class="output-right-aligned">{{calculationRequest.term }} {{"bulk-calculation-form.month" | translate}}</span>
        </div>

        <!-- Restschuldversicherung -->
        <div *ngIf="!!calculationResponse.cliAmount" class="flex-container">
            <label>{{"app.cliAmount" | translate}}</label>
            <span class="output-right-aligned">{{calculationResponse.cliAmount | customDecimal:2:',':'.'}} €</span>
        </div>

        <!-- Effektiver Jahreszins -->
        <div class="flex-container">
            <label>{{"app.interestRateEffective" | translate}}*
                <toyrr-tooltip ttId="interestRateEffective">{{"app.interestRateEffective.tooltip" | translate}}
                </toyrr-tooltip>
            </label>
            <span class="output-right-aligned">{{calculationResponse.interestRateEffective| customDecimal:2:',':'.'}} %</span>
        </div>

        <!-- Gebundener Sollzins -->
        <div class="flex-container">
            <label>{{"app.interestRateNominal" | translate}}*</label>
            <span class="output-right-aligned">{{calculationResponse.interestRateNominal | customDecimal:2:',':'.'}} %</span>
        </div>

        <!-- Gesamtsumme -->
        <div class="flex-container">
            <label>{{"app.totalFinanceAmount" | translate}}*
                <toyrr-tooltip ttId="totalFinanceAmount">{{"app.totalFinanceAmount.tooltip" | translate}}
                </toyrr-tooltip>
            </label>
            <span class="output-right-aligned">{{calculationResponse.totalFinanceAmount | customDecimal:2:',':'.'}} €</span>
        </div>

        <!-- Schlussrate -->
        <div *ngIf="calculationRequest.balloonAmountRequested" class="flex-container">
          <label>{{"calculation-form.balloon" | translate}}</label>
          <span class="output-right-aligned">
              {{calculationRequest.balloonAmount | customDecimal:2:',':'.'}} €
            </span>
        </div>

        <hr>

        <!-- Monatliche Rate -->
        <div class="flex-container">
            <label>{{calculationRequest.balloonAmountRequested? calculationRequest.term - 1: calculationRequest.term}}
                {{"app.termAndInstalment" | translate}}*</label>
            <span class="output-right-aligned">{{calculationResponse.instalment | customDecimal:2:',':'.'}} €</span>
        </div>
    </div>

</div>

<hr noshade>

<span class="output-right">
  <h2>
    <a class="no-link" [ngClass]="{disabled: !inputFormValid || errorService.errors.length !==0}">
      <span [ngClass]="{disabled: !inputFormValid || errorService.errors.length !==0}" class="icon large icon-print"
         (click)="generatePdf()" title="{{ 'app.print.tooltip' | translate}}"></span>
    </a>
    <a class="no-link" [ngClass]="{disabled: !inputFormValid || errorService.errors.length !==0}"
       (click)="showEmailDialog = !showEmailDialog">
      <span [ngClass]="{disabled: !inputFormValid || errorService.errors.length !==0}" class="icon large icon-email rr-icon-envelope-alt"
         title="{{ 'app.email.tooltip' | translate}}"></span>
    </a>
  </h2>
</span>
