import { Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CalculationResponse } from '../classes/responses/calculation-response';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PdfService {

    constructor(public formatter: FormattedStringToNumberPipe,
                public configService: ConfigService) {
    }


    generatePdf(request: CalculationRequest, result: CalculationResponse, language: string): string {
        let params = new HttpParams();

        params = params.set('productID', request.productID);
        params = params.set('term', request.term);
        params = params.set('downpayment', this.formatter.transform(request.downpayment));
        params = params.set('balloonAmount', this.formatter.transform(request.balloonAmount));
        params = params.set('carPrice', this.formatter.transform(request.carPrice));
        params = params.set('cliRequested', request.cliRequested);
        params = params.set('interestRate', request.interestRate);
        params = params.set('mileage', request.mileage);
        params = params.set('language', language);
        params = params.set('carType', request.carType);

        let returnUrl = this.configService.config.urls.pdf + '?' + params.toString();

        if(this.configService.config.dealerID)
          returnUrl += '&dealerId=' + this.configService.config.dealerID;

        return returnUrl;
    }

}
