const SERVER_BASE_URL = 'https://api.dev.toyota-tools.itsdcloud.com';

export const environment = {
  production: false,
  ratenrechner: {
    defaultLanguage: 'de',
    urls: {
      i18n: `${SERVER_BASE_URL}/language`,
      auth: `${SERVER_BASE_URL}/auth/anonymous`,
      initialCalculation: `${SERVER_BASE_URL}/api/v2/calculateInitial`,
      calculateBalloon: `${SERVER_BASE_URL}/api/v2/calculateBalloon`,
      calculation: `${SERVER_BASE_URL}/api/v2/calculate`,
      bulkCalculation: `#baseURL#/api/v2/calculateBulk`,
      products: `${SERVER_BASE_URL}/api/v2/products`,
      email: `${SERVER_BASE_URL}/api/v2/sendCalculationMail`,
      pdf: `${SERVER_BASE_URL}/v2/pdf`,
      econdaLib: '',
      of: `https://testzone1.toyota-bank-portal.de/of/`
    },
    productServiceFilter: 'itsdCalculateLoan',
    googleTagMangerCode: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','gwDataLayer','GTM-MPSCFFD')"
  },
  budgetrechner: {
    defaultLanguage: 'de',
    urls: {
      i18n: `${SERVER_BASE_URL}/language`,
      auth: `${SERVER_BASE_URL}/auth/anonymous`,
      calculation: `${SERVER_BASE_URL}/api/v1/calculateBudget`,
      email: `${SERVER_BASE_URL}/api/v1/sendBudgetMail`,
      pdfBudget: `${SERVER_BASE_URL}/pdfBudget`,
      econdaLib: `http://serviceprovider-test.itsd-consulting.com/js/emos3.js`
    }
  }
};
