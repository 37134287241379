import { CalculationResponse } from './calculation-response';

export class DefaultCalculationResponse extends CalculationResponse {

    public term: number;
    public balloonAmount: number;
    public downpayment: number;
    public cliRequested: boolean;


    /**
     * Factory method to create new CalculationRequest from existing data
     * @param data Already existing CalculationRequest data
     * @returns Instance of CalculationRequest with the given data
     */
    public static fromData(data): DefaultCalculationResponse {
        let res: DefaultCalculationResponse = new DefaultCalculationResponse();

        res.instalment = data.instalment;
        res.cliAmount = data.cliAmount;
        res.adminFee = data.adminFee;
        res.totalFinanceAmount = data.totalFinanceAmount;
        res.interestRateEffective = data.interestRateEffective;
        res.interestRateNominal = data.interestRateNominal;
        res.netFinanceAmount = data.netFinanceAmount;
        res.term = data.term;
        res.balloonAmount = data.balloonAmount;
        res.downpayment = data.downpayment;
        res.cliRequested = data.cliRequested;
        res.error = data.error;

        return res;
    }

}
