import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';

import { ErrorService } from './error.service';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CalculationResponse } from '../classes/responses/calculation-response';
import { BulkCalculationResponse } from '../classes/responses/bulk-calculation-response';
import { Error } from '../classes/error';
import { Observable, of } from 'rxjs';
import { ErrorCode } from '../constants/error-code';
import { DefaultCalculationResponse } from '../classes/responses/default-calculation-response';
import { CookieHandleService } from './cookie-handle.service';
import { TrackingService } from './tracking.service';
import { TrackingEvent } from '../classes/events/tracking-event';
import { EventType } from '../constants/event-type';
import { CustomDecimalPipe } from '../pipes/custom-decimal.pipe';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Product } from '../classes/requests/product';

@Injectable()
export class CalculationService {

  _requestChanged = new EventEmitter<CalculationRequest>();
  _calculationChanged = new EventEmitter<CalculationResponse>();

  _lastSubmittedRequest: CalculationRequest = new CalculationRequest();
  _lastRequest: CalculationRequest = CalculationRequest.default();
  _lastResponse: CalculationResponse = new CalculationResponse();
  defaultResponse: DefaultCalculationResponse = new DefaultCalculationResponse();
  _readyForCalc = true;

  constructor(public errorService: ErrorService,
              public http: HttpClient,
              public formatter: FormattedStringToNumberPipe,
              public cookieHandleService: CookieHandleService,
              public customDecimal: CustomDecimalPipe,
              public configService: ConfigService,
              public trackingService: TrackingService) {
  }

  // Get EventEmitters
  get requestChanged(): EventEmitter<CalculationRequest> {
    return this._requestChanged;
  }

  get calculationChanged(): EventEmitter<CalculationResponse> {
    return this._calculationChanged;
  }


  // Check/Set if service is ready for calculation

  /**
   * Legt fest, ob die Anwendung bereit für eine Berechnung ist.
   * @param readyForCalc true, falls die Anwendung bereit für eine Berechnung ist, false, falls nicht
   */
  setReadyForCalc(readyForCalc: boolean): void {
    this._readyForCalc = readyForCalc;
  }

  /**
   * Prüft ob die Anwendung bereit für eine Berechnung ist.
   * @returns true, falls die Anwendung bereit für eine Berechnung ist, false, falls nicht
   */
  isReadyForCalc(): boolean {
    return this._readyForCalc;
  }


  // Calculations

  /**
   * Startet eine initiale Berechnung.
   * @param request Werte mit denen die initiale Berechnung ausgeführt werden soll
   */
  getDefaultValues(request: CalculationRequest): Observable<DefaultCalculationResponse> {
    return new Observable<DefaultCalculationResponse>(observer => {
      let params = new HttpParams();

      if(!request.productID)
        return;

      params = params.set('productID', request.productID);
      params = params.set('interestRate', request.interestRate);
      params = params.set('carPrice', request.carPrice);

      let customHeaders = new HttpHeaders();
      if(this.configService.config.dealerID){
        customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
      }

      this.http.get(this.configService.config.urls.initialCalculation, {headers: customHeaders, params: params}).pipe(
        map(res => DefaultCalculationResponse.fromData(res)))
        .subscribe(
          (data: DefaultCalculationResponse) => {
            if (this.handleErrors(data)) {
              // console.log('ERROR: ' + JSON.stringify(data.error));
            } else {
              this.lastRequest = request;
              this.lastSubmittedRequest = CalculationRequest.fromData(request);
              this.lastSubmittedRequest.balloonAmount = data.balloonAmount;
              this.lastSubmittedRequest.downpayment = data.downpayment;
              this.lastSubmittedRequest.cliRequested = data.cliRequested;
              this.lastSubmittedRequest.term = data.term;

              this.lastResponse = data;
              // console.log('INITIAL CALCULATION:\n' + JSON.stringify(data));
              this.errorService.okAll(ErrorCode.CALCULATION_ERRORS);

              // Track calculation
              const calcData = [request, data];
              this.trackingService.initialCalcEmitter.emit(new TrackingEvent(EventType.INITIAL_CALCULATION, calcData));
            }

            this.defaultResponse = data;

            observer.next(data);
            observer.complete();

          },
          (err) => {
            this.errorService.error(this.errorService.generalError());
            observer.error();
            observer.complete();
          }
        );
    });
  }

  get lastRequest(): CalculationRequest {
    // this._requestChanged.emit(this._lastRequest);
    return this._lastRequest;
  }

  private formatValues(request: CalculationRequest): CalculationRequest{

    let decimalPlaces = 2;

    request.balloonAmount = this.customDecimal.transform(request.balloonAmount, decimalPlaces, ',', '.', '');
    request.carPrice = this.customDecimal.transform(request.carPrice, decimalPlaces, ',', '.', '');
    request.downpayment = this.customDecimal.transform(request.downpayment, decimalPlaces, ',', '.', '');

    return request;
  }

  /**
   * Formatierung der Inputfelder nach einem Submit
   */
  formatRawNumber(formattedValue: any): string {
    formattedValue = this.formatter.transform(formattedValue);
    return this.customDecimal.transform(formattedValue, 2, ',', '.', '');
  }

  /**
   * Schreibt die von der default Calculation vorgeschriebenen Werte in einen CalculationRequest
   * @param req Der Request, auf den die Werte übernommen werden soll
   * @returns Der angepasste CalculationRequest
   */
  applyDefaultCalculationToRequest(req: CalculationRequest): CalculationRequest {
    const request: CalculationRequest = req;

    request.term = this.defaultResponse.term;
    request.downpayment = this.defaultResponse.downpayment;
    request.balloonAmount = this.defaultResponse.balloonAmount;
    request.cliRequested = this.defaultResponse.cliRequested;
    request.interestRate = this.defaultResponse.interestRateEffective;

    return request;
  }


  // Error Handling

  /**
   * Prüft ob ein Fehler bei der Berechnung aufgetreten ist.
   * @param result Das Berechnungs-Ergebnis, das vom Serviceprovider kommt
   * @returns true, falls Fehler aufgetreten sind, false, falls nicht
   */
  handleErrors(result: CalculationResponse): boolean {
    // No errors existing
    if (!result.error) {
      this.errorService.clearErrors();
      return false;
    }

    const error: Error = new Error(result.error.errorCode, result.error.errorMessage);
    this.errorService.okAll([result.error.errorCode.toString()]);
    this.errorService.error(error);

    return true;
  }

  // Getters / Setters

  get lastSubmittedRequest(): CalculationRequest {
    return this._lastSubmittedRequest;
  }

  set lastSubmittedRequest(request: CalculationRequest) {
    this._lastSubmittedRequest = request;
  }

  /**
   * Gibt eine Liste vom Typ Produkt zurück. Die Produkt ID ist für den CalculationRequest relevant
   * returns Observable<Product[]>
   * EDIT: Es soll nur das Prodkut mit dem service "itsdCalculateLoan" zurückgegeben werden
   */
  getProducts(): Observable<Product> {
    let customHeaders = new HttpHeaders();
    if(this.configService.config.dealerID){
      customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
    }

    return this.http.get<Product[]>(this.configService.config.urls.products, {headers: customHeaders})
      .pipe(
        timeout(5000),
        mergeMap((products: Product[]) => products.filter(product => product.service == this.configService.config.productServiceFilter)),
        catchError(e => {
          // do something on a timeout
          this.errorService.error(this.errorService.generalError());
          return of(null);
        })
      );
  }
/*

.filter(product => product.service == "terst")

.pipe(
    mergeMap((products: Product[]) => products),
  first()
);*/

  /**
   * Führt eine Berechnung aus.
   * @param request Werte, mit denen die Berechnung ausgeführt werden soll
   */
  getCalculationResult(request: CalculationRequest, initial?: boolean, track = true): Observable<CalculationResponse> {
    return new Observable<CalculationResponse>(observer => {

      let params = new HttpParams();

      if(!request.productID)
        return;

      params = params.set('productID', request.productID);
      params = params.set('term', request.term);
      params = params.set('downpayment', this.formatter.transform(request.downpayment));
      request.downpayment = this.formatRawNumber(request.downpayment);
      params = params.set('balloonAmount', this.formatter.transform(request.balloonAmount));
      request.balloonAmount = this.formatRawNumber(request.balloonAmount);
      params = params.set('carPrice', this.formatter.transform(request.carPrice));
      request.carPrice = this.formatRawNumber(request.carPrice);
      params = params.set('cliRequested', String(request.cliRequested));
      params = params.set('interestRate', request.interestRate);
      params = params.set('mileage', request.mileage);
      params = params.set('carType', request.carType);

      let customHeaders = new HttpHeaders();
      if(this.configService.config.dealerID){
        customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
      }

      this.http.get(this.configService.config.urls.calculation, {headers: customHeaders, params: params}).pipe(
        timeout(5000),
        map(res => CalculationResponse.fromData(res)))
        .subscribe(
          (data: CalculationResponse) => {
            if (this.handleErrors(data)) {
              // console.log('ERRORS: ' + JSON.stringify(this.errorService.errors));
            } else {
              this.lastRequest = request;
              this.lastResponse = data;

              // console.log('CALCULATION:\n' + JSON.stringify(data));
              this.errorService.okAll(ErrorCode.CALCULATION_ERRORS);
              this.cookieHandleService.saveCookie(this.configService.config.reqCookieName, CalculationRequest.forCache(request), this.configService.config.reqCookieDuration);

              // Track calculation
              const calcData = [request, data];

              // Do not track calculation
              if (!track) {
                observer.next(data);
                observer.complete();
                return;
              }

              if (initial) {
                // Track initial calculation
                this.trackingService.initialCalcEmitter.emit(new TrackingEvent(EventType.INITIAL_CALCULATION, calcData));
              } else {
                // Track calculation
                this.trackingService.calcEmitter.emit(new TrackingEvent(EventType.CALCULATION, calcData));
              }
            }
            observer.next(data);
            observer.complete();
          },
          (err) => {
            this.errorService.error(this.errorService.generalError());
            observer.error(err);
          }
        );


    });
  }

  /**
   * Ermittelt für den Request die höchste Schlussrate
   * @param request Der request, ohne die Schlussrate
   */
  getCalculationResultWithBalloonAmount(request: CalculationRequest, initial?: boolean, track = true): Observable<CalculationResponse> {

    let params = new HttpParams();

    if(!request.productID)
      return;

    params = params.set('productID', request.productID);
    params = params.set('term', request.term);
    params = params.set('downpayment', this.formatter.transform(request.downpayment));
    request.downpayment = this.formatRawNumber(request.downpayment);
    params = params.set('carPrice', this.formatter.transform(request.carPrice));
    request.carPrice = this.formatRawNumber(request.carPrice);
    params = params.set('cliRequested', String(request.cliRequested));
    params = params.set('interestRate', request.interestRate);
    params = params.set('mileage', request.mileage);
    params = params.set('carType', request.carType);

    let customHeaders = new HttpHeaders();
    if(this.configService.config.dealerID){
      customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
    }

    return new Observable<CalculationResponse>(observer => {

      this.http.get<CalculationResponse>(this.configService.config.urls.calculateBalloon, {
        headers: customHeaders,
        params: params
      }).pipe(
        timeout(5000),
        map(res => CalculationResponse.fromData(res)))
        .subscribe(
          (data: CalculationResponse) => {

            if (this.handleErrors(data)) {
              //console.log('ERRORS:' + data);
            } else {
              this.lastRequest = request;
              this.lastResponse = data;

              //set ballonAmount in Request
              this.lastRequest.balloonAmount = this.formatRawNumber(data.balloonAmount);
              this.requestChanged.emit(this.lastRequest);

              // console.log('CALCULATION:\n' + JSON.stringify(data));
              this.errorService.okAll(ErrorCode.CALCULATION_ERRORS);
              this.cookieHandleService.saveCookie(this.configService.config.reqCookieName, CalculationRequest.forCache(request), this.configService.config.reqCookieDuration);

              // Track calculation
              const calcData = [request, data];

              // Do not track calculation
              if (!track) {
                observer.next(data);
                observer.complete();
                return;
              }

              if (initial) {
                // Track initial calculation
                this.trackingService.initialCalcEmitter.emit(new TrackingEvent(EventType.INITIAL_CALCULATION, calcData));
              } else {
                // Track calculation
                this.trackingService.calcEmitter.emit(new TrackingEvent(EventType.CALCULATION, calcData));
              }
            }
            observer.next(data);
            observer.complete();
          },
          (err) => {
            this.errorService.error(this.errorService.generalError());
            observer.error(err);
          });
    });

        /*
        catchError((err: any) => {
        this.errorService.error(this.errorService.generalError());
        return of(null);
        */
      //}));
  }

  /**
  * Führt eine Massenkalkulation durch
  */
  getBulkCalculationResults(calculationRequests: CalculationRequest[]): Observable<BulkCalculationResponse> {

    let customHeaders = new HttpHeaders();
    if(this.configService.config.dealerID){
      customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
    }

    return this.http.post<CalculationResponse>(this.configService.config.urls.bulkCalculation, {calculationRequests}, {headers: customHeaders})
      .pipe(
        timeout(5000),
        catchError((err: any) => {
        this.errorService.error(this.errorService.generalError());
        return of(null);
      }));
  }

  set lastRequest(pRequest: CalculationRequest) {
    this._lastRequest = pRequest;

    if (pRequest) {
      this._requestChanged.emit(this.formatValues(this.lastRequest));
    }
  }

  get lastResponse(): CalculationResponse {
    return this._lastResponse;
  }

  set lastResponse(value: CalculationResponse) {
    this._lastResponse = value;

    if (value) {
      this.calculationChanged.emit(this.lastResponse);
    }
  }

}
