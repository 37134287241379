<form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
    <div class="modal blur-off" *ngIf="!servOn">
        <span> Service steht zur Zeit nicht zur Verfügung / Service is temporarily unavailable</span>
    </div>
    <div [class.serverOffline]="!servOn">
        <div class="mainsection">
            <div class="input">
                <!-- Überschrift -->
                <h2 id="calc-title">{{"app.budget" | translate}}</h2>
                <hr noshade>

                <div class="input-group abstand-container">
                    <!-- Monatliches Budget -->
                    <div class="input-element abstand-box">
                        <toybr-currency-input
                                formControlName="instalment"
                                [label]="'calculation-form.budget.instalment' | translate"
                                [(ngModel)]="request.instalment"
                                [hasError]="!inputForm.controls['instalment'].valid"
                                (inputChangeReady)="onSubmit()">
                        </toybr-currency-input>
                        <small class="errorMessage" *ngIf="!inputForm.controls['instalment'].valid">
                            {{"calculation-form.budget.invalidValue" |
                            translate}}
                        </small>
                    </div>

                    <!-- Anzahlung -->
                    <div class="input-element abstand-box">
                        <toybr-currency-input
                                formControlName="downpayment"
                                [label]="'calculation-form.budget.downpayment' | translate"
                                [(ngModel)]="request.downpayment"
                                [hasError]="!inputForm.controls['downpayment'].valid"
                                (inputChangeReady)="onSubmit()">
                        </toybr-currency-input>
                        <small class="errorMessage" *ngIf="!inputForm.controls['downpayment'].valid">
                            {{"calculation-form.budget.invalidValue" |
                            translate}}
                        </small>
                    </div>
                </div>


                <div class="input-group-reverse balloon-container">
                    <!-- Mit Schlussrate finanzieren -->
                    <div class="input-element abstand-box">
                        <input type="checkbox" id="mit-schlussrate" class="checkbox" formControlName="balloonRequest"
                               [(ngModel)]="request.balloonRequest" (change)="onSubmit()">
                        <label for="mit-schlussrate">
                            <span>
                                {{"calculation-form.budget.balloonAmountRequested" | translate}}
                            </span>
                        <toybr-tooltip ttId="balloonAmountRequest">
                            {{"calculation-form.budget.balloonAmountRequested.tooltip" | translate}}
                        </toybr-tooltip>
                        </label>
                    </div>
                </div>
                <!-- KM-Auswahl -->
                <div class="input-group-reverse abstand-container" *ngIf="request.balloonRequest">
                    <div class="input-element abstand-box">
                        <label class="select-label">{{"calculation-form.mileage" | translate}}</label>
                        <div class="select-wrapper">
                        <select (change)="onMileAgeChange($event.target.value);onSubmit()" class="input-element"
                                formControlName="mileage">
                            <option *ngFor="let option of POSSIBLE_MILEAGE" [value]="option.value"
                                    [attr.selected]="inputForm.controls['mileage'].value==option.value? true: null">
                                {{option.key}} km
                            </option>
                        </select>
                        </div>
                        <small class="errorMessage" *ngIf="!inputForm.controls['downpayment'].valid">
                            {{"calculation-form.budget.invalidValue" |
                            translate}}
                        </small>
                    </div>
                </div>
                <!-- Laufzeit in Monaten -->
                <div class="laufzeit-group-wrapper abstand-container-4">
                <label for="laufzeit-group">{{"calculation-form.budget.term" | translate}}</label>
                <div id="laufzeit-group" class="flex-container">
                    <div *ngFor="let t of POSSIBLE_TERMS; let i = index" class="laufzeit-group">
                        <input #term type="radio" formControlName="term" (change)="onTermChange(t); onSubmit()"
                               [id]="'term-' + t"
                               [value]="t"
                               [(ngModel)]="request.term">
                        <label [for]="'term-' + t">{{t}}</label>
                    </div>
                </div>
                </div>

                <!-- Finanzierung berechnen -->
                <div class="calc-and-error">

                    <!-- Fehlermeldungen -->
                    <div id="errorbox-container" *ngIf="errorService.errors.length > 0">
                        <span *ngFor="let error of errorService.errors">
                            <toybr-error-box [error]="error"></toybr-error-box>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Output -->
            <div class="output">
                <!-- Überschrift -->
                <div class="flex-container">
                    <h2>{{"app.budgetResult" | translate}}</h2>
                </div>

                <hr noshade>

                <div class="calc-output">
                    <!-- Loader -->
                    <div id="calc-loader" *ngIf="showCalcLoader" class="loader"></div>

                    <!-- Calculation Output -->
                    <div [class.blur-on]="showCalcLoader" [class.blur-off]="!showCalcLoader">
                        <!-- Anzahlung -->
                        <div class="flex-container">
                            <label>{{'calculation-form.budget.downpayment' | translate}}
                                <toybr-tooltip ttId="downpayment">{{'calculation-form.budget.downpayment.tooltip' |
                                    translate}}
                                </toybr-tooltip>
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastRequest.downpayment}} €</span>
                        </div>

                        <!-- Restschuldversicherung -->
                        <div class="flex-container" *ngIf='calculationService.lastRequest.cliRequest'>
                            <label>
                                {{'calculation-form.budget.cliRequested'| translate}}
                                <!--<toybr-tooltip>{{'' | translate}}</toybr-tooltip>-->
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.cliAmount | customDecimal:2:',':'.'}} €</span>
                        </div>
                        <!-- Maximal mögliche Schlussrate -->
                        <div *ngIf='calculationService.lastRequest.balloonRequest' class="flex-container">
                            <label>{{"calculation-form.budget.balloonAmount" | translate}}*
                                <toybr-tooltip ttId="balloonAmount">
                                    {{"calculation-form.budget.balloonAmountRequested.tooltip" | translate}}
                                </toybr-tooltip>
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.balloonAmount | customDecimal:2:',':'.'}} €</span>
                        </div>
                        <!-- Effektiver Jahreszins -->
                        <div class="flex-container">
                            <label>{{"app.interestRateEffective" | translate}}*
                                <toybr-tooltip ttId="interestRateEffective">{{"app.interestRateEffective.tooltip" |
                                    translate}}
                                </toybr-tooltip>
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.interestRateEffective | customDecimal:2:',':'.'}} %</span>
                        </div>
                        <!-- Gebundener Sollzins -->
                        <div class="flex-container">
                            <label>{{"app.interestRateNominal" | translate}}*</label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.interestRateNominal | customDecimal:2:',':'.'}} %</span>
                        </div>
                        <!-- Wunschkredit -->
                        <div class="flex-container">
                            <label>{{'calculation-form.budget.desiredCredit' | translate}}
                                <toybr-tooltip ttId="desiredCredit">{{'app.netFinanceAmount.tooltip'|translate}}
                                </toybr-tooltip>
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.desiredCredit | customDecimal:2:',':'.'}} €</span>
                        </div>
                        <hr noshade>
                        <!-- Gesamtbetrag -->
                        <div class="flex-container">
                            <label>{{'calculation-form.budget.total' | translate}}*
                                <toybr-tooltip ttId="total">{{'calculation-form.budget.total.tooltip' |
                                    translate}}
                                </toybr-tooltip>
                            </label>
                            <span class="output-right-aligned">{{calculationService.lastResponse.totalFinanceAmount | customDecimal:2:',':'.'}} €</span>
                        </div>
                    </div>
                    <span class="output-right">
                        <h2>
                        <a class="no-link "
                           [ngClass]="{disabled: inputForm.invalid || errorService.errors.length !==0}">
                            <span
                                [ngClass]="{disabled: inputForm.invalid || errorService.errors.length !==0}"
                                class="icon large icon-print"
                                (click)="generatePdf()" title="{{ 'app.print.tooltip' | translate}}">
                            </span>
                        </a>
                        <a class="no-link" [ngClass]="{disabled: inputForm.invalid || errorService.errors.length !==0}"
                           (click)="showEmailDialog = !showEmailDialog">
                            <span
                                class="icon large icon-mail icon-envelope-alt"
                                [ngClass]="{disabled: inputForm.invalid || errorService.errors.length !==0}"
                                title="{{ 'app.email.tooltip' | translate}}">
                            </span>
                        </a>
                      </h2>
                    </span>
                    <!-- E-Mail Form -->
                    <div class="email-form-wrapper">
                        <toybr-email-form [calculationRequest]="request" (cancel)="showEmailDialog = false"
                                          *ngIf="showEmailDialog && !(inputForm.invalid || errorService.errors.length !==0)">
                        </toybr-email-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="calcform-footer">
            <!-- Fahrzeugkredit -->
            <h2>{{'calculation-form.budget.carLoan' | translate}}*</h2><br>
            <span id="car-price" [@calculatingState]="calculatingState">{{calculationService.lastResponse.carPrice | customDecimal:2:',':'.'}} €</span>
            <span *ngIf="showSearch" class="matching-cars">
                <div class="button red" (click)="goToMatchingCars()">Passende Fahrzeuge suchen</div>
            </span>
        </div>
        <!-- Legal Info -->
        <div id="legal-info">
            <p id="star">*</p>
            <div [innerHTML]="'app.legalInfo' | translate"></div>
        </div>
    </div>
</form>
