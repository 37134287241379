<div *ngIf="show" class="cookie-hint">
    <div class="cookie-hint-header">
        <button type="button"
                class="close-button"
                (click)="close()">
            <span class="icon icon-remove"></span>
        </button>
    </div>

    <div class="cookie-hint-content">
        <p>
            Um unser Internetangebot für Sie optimal zu gestalten und fortlaufend verbessern zu können,
            verwenden wir Cookies sowie das Webanalyse-Tool Google Analytics.
            Durch die weitere Nutzung dieser Webseite erklären Sie sich hiermit einverstanden.
            Eine Widerspruchsmöglichkeit gibt es
            <a href="https://toyota-bank-portal.de/ratenrechner/datenschutz.html"
               target="_blank"
               class="light">hier</a>
        </p>
    </div>

    <div class="cookie-hint-footer">
        <button type="button" class="button red button-small accept-button" (click)="accept()">OK</button>
    </div>
</div>
