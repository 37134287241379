import { mergeMap } from 'rxjs/operators';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthorizationService } from '../shared/authorization.service';
import { ConfigService } from '../shared/config.service';
import { RatenrechnerUrlConfig } from '../config/ratenrechner-url-config';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    authService: AuthorizationService;


  constructor(private injector: Injector) {
  }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
      const configService = this.injector.get(ConfigService);

        // Interceptor is not needed
        if (!this.isNeeded(request.url, configService.config.urls)) {
            return next.handle(request);
        }

        // Do not intercept on auth request
        if (request.url === configService.config.urls.auth ||
          request.url === configService.config.urls.i18n) {
            return next.handle(request);
        }

      this.authService = this.injector.get(AuthorizationService);

        if (!this.authService.token) {
            return next.handle(request);
        }

        const expDate = this.authService.jwtHelper.getTokenExpirationDate(this.authService.token);

        // Handle request
        request = this.addAuthHeader(request);

        // Token is not valid (anymore)
        if (!this.authService.isTokenValid()) {

            return this.authService.authorize().pipe(mergeMap((result) => {
                // Update request with new token
                request = this.addAuthHeader(request);

                return next.handle(request);
            }));
        } else {
            // Handle response
            return next.handle(request);
        }
    }


    addAuthHeader(request) {
        const authHeader = this.authService.authorizationHeader;

        if (authHeader) {
            return request.clone({
                setHeaders: {
                  Authorization: authHeader
                }
            });
        }

        return request;
    }

    isNeeded(url: string, urls: RatenrechnerUrlConfig): boolean {
      for (const key in urls) {
            if (url === urls[key]) {
                return true;
            }
        }

        return false;
    }

}
