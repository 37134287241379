import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RatenrechnerModule } from '@toyota-tools/ratenrechner';
import { environment } from '../environments/environment';
import { BudgetrechnerModule } from '@toyota-tools/budgetrechner';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RatenrechnerModule.forRoot({
      defaultLanguage: environment.ratenrechner.defaultLanguage,
      urls: environment.ratenrechner.urls,
      googleTagMangerCode: environment.ratenrechner.googleTagMangerCode,
    }),
    BudgetrechnerModule.forRoot({
      defaultLanguage: environment.budgetrechner.defaultLanguage,
      urls: environment.budgetrechner.urls,
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
