import { EventEmitter, Injectable } from '@angular/core';
import { TrackingEvent } from '../classes/events/tracking-event';
import { ConfigService } from './config.service';
import { PriceUtils } from '../utils/price-utils';
import { CookieHandleService } from './cookie-handle.service';
import { TranslateService } from '@ngx-translate/core';

declare const ga: any;

const ROUND_TO = 500;
const RATE_ROUND_TO = 100;

@Injectable()
export class TrackingService {

  private ga: any;

  private wasVisible: boolean;
  private isGoogleTagMangerActive = false;

  pageViewEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  initialCalcEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  calcEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  printButtonEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  mailButtonEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  ofButtonEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  tooltipShowEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  tooltipHideEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();
  valueChangeEmitter: EventEmitter<TrackingEvent> = new EventEmitter<TrackingEvent>();


  constructor(private configService: ConfigService,
              private cookieHandleService: CookieHandleService,
              private translateService: TranslateService ) {
    this.setupExternalFunctions();

    if(this.configService.config.googleTagMangerCode) {
      // check if disableCookie is set
      if (this.cookieHandleService.isPresent('gtm-disabled')) {
        console.log('GTM Tracking disabled');
      } else {
        this.loadGTMScript();
      }
    } else if (this.configService.config.analyticsTrackerName && this.configService.config.analyticsTrackingId) {
      this.isGoogleTagMangerActive = false;
      this.loadGAScript();
    }

    this.pageViewEmitter.subscribe((event) => this.onPageView(event));
    this.initialCalcEmitter.subscribe((event) => this.onInitialCalc(event));
    this.calcEmitter.subscribe((event) => this.onCalc(event));
    this.printButtonEmitter.subscribe((event) => this.onPrintButtonClick(event));
    this.mailButtonEmitter.subscribe((event) => this.onMailButtonClick(event));
    this.ofButtonEmitter.subscribe((event) => this.onOfButtonClick(event));
    this.tooltipShowEmitter.subscribe((event) => this.onToolTipShow(event));
    this.tooltipHideEmitter.subscribe((event) => this.onToolTipHide(event));
    this.valueChangeEmitter.subscribe((event) => this.onValueChange(event));
  }


  loadGTMScript(): void {
    // Load Google Tag Manger Script
    // do not put in script tag, but execute directly
    eval(this.configService.config.googleTagMangerCode);

    /*
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');

    script.appendChild(document.createTextNode(this.configService.config.googleTagMangerCode));
    head.appendChild(script);
    */
  }

  loadGAScript(): void {
    // Load Google Analytics
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = Date.now();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    this.ga = window['ga'] || function () {
      (this.ga.q = this.ga.q || []).push(arguments);
    };
    this.ga.l = +new Date();

    const trackerName = this.configService.config.analyticsTrackerName;

    ga('create', this.configService.config.analyticsTrackingId, 'auto', trackerName);
    ga(`${trackerName}.set`, 'anonymizeIp', true);
    ga('send', 'pageview');
  }

  setDisableCookie() {
    if (this.configService.config.analyticsTrackerName && this.configService.config.analyticsTrackingId) {
      // disable for GoogleAnalytics
      this.cookieHandleService.saveStandardCookie('ga-disable-UA-' + this.configService.config.analyticsTrackingId, 'true', 36500);
      alert(this.translateService.instant('app.legalInfo.trackingDisabled'));
    } else if (this.configService.config.googleTagMangerCode) {
      // set disable Cookie for GTM
      this.cookieHandleService.saveStandardCookie('gtm-disabled', 'true', 36500);
      alert(this.translateService.instant('app.legalInfo.trackingDisabled'));
    }
  }

  /**
   * Make functions callable from outside
   */
  setupExternalFunctions() {
    window['toyota'] = window['toyota'] || {};
    window['toyota'].ratenrechner = window['toyota']['ratenrechner'] || {};
    window['toyota'].ratenrechner.disableTracking = this.setDisableCookie.bind(this);
  }


  /**
   * Send an event to Google Analytics
   * @param category The event category
   * @param action The event action
   * @param label The event label
   */
  trackEvent(category: string, action: string, label: string) {
    if (!this.isGoogleTagMangerActive && this.ga) {
      this.ga(`${this.configService.config.analyticsTrackerName}.send`, {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      });
    }
  }

  /**
   * Set properties for the current user
   * @param properties The properties to set
   */
  set(properties: any) {
    if (!this.isGoogleTagMangerActive && this.ga) {
      this.ga(`${this.configService.config.analyticsTrackerName}.set`, properties);
    }
  }


  /**
   * Wird aufgerufen, wenn der Rechner aufgerufen wird
   */
  onPageView(event: TrackingEvent): void {
    // Page view is tracked in loadScript()
  }

  /**
   * Is called when the visibility has been updated
   * @param visibility Whether the application is completely visible or not
   */
  onVisibilityChange(visibility: boolean): void {
    // Visibility has already been tracked => ignore
    if (!visibility || this.wasVisible) {
      return;
    }

    this.trackEvent('visible', 'visibilityChange', 'Application is now fully visible');

    this.wasVisible = true;
  }


  onInitialCalc(event: TrackingEvent): void {
    const properties = {
      dimension1: PriceUtils.round(event.data[0].carPrice, ROUND_TO),
      dimension2: event.data[0].term,
      dimension3: PriceUtils.round(event.data[0].downpayment, ROUND_TO),
      dimension4: PriceUtils.round(event.data[0].balloonAmount, ROUND_TO),
      dimension5: event.data[0].balloonAmountRequested,
      dimension6: event.data[0].cliRequested,
      dimension7: PriceUtils.round(event.data[1].instalment, ROUND_TO),
      dimension8: event.data[1].interestRateEffective,
      dimension9: PriceUtils.round(event.data[1].totalFinanceAmount, RATE_ROUND_TO)
    };

    this.set(properties);
  }

  /**
   * Wird aufgerufen, wenn eine Berechnung durchgeführt wird
   */
  onCalc(event: TrackingEvent): void {
    const properties = {
      dimension1: PriceUtils.round(event.data[0].carPrice, ROUND_TO),
      dimension2: event.data[0].term,
      dimension3: PriceUtils.round(event.data[0].downpayment, ROUND_TO),
      dimension4: PriceUtils.round(event.data[0].balloonAmount, ROUND_TO),
      dimension5: event.data[0].balloonAmountRequested,
      dimension6: event.data[0].cliRequested,
      dimension7: PriceUtils.round(event.data[1].instalment, ROUND_TO),
      dimension8: event.data[1].interestRateEffective,
      dimension9: PriceUtils.round(event.data[1].totalFinanceAmount, RATE_ROUND_TO)
    };

    this.set(properties);
  }

  /**
   * Wird aufgerufen, wenn der Drucken-Button geklickt wird
   */
  onPrintButtonClick(event: TrackingEvent): void {
    this.trackEvent('print', 'printButtonClick', 'Print button has been clicked');
  }

  /**
   * Wird aufgerufen wenn der 'E-Mail senden'-Button geklickt wird
   */
  onMailButtonClick(event: TrackingEvent): void {
    this.trackEvent('mail', 'mailButtonClick', 'Mail button has been clicked');
  }

  /**
   * Wird aufgerufen wenn der 'Finanzierung Anfragen'-Button geklickt wird
   */
  onOfButtonClick(event: TrackingEvent): void {
    this.trackEvent('of', 'ofButtonClick', 'OF button has been clicked');
  }

  /**
   * Wird aufgerufen wenn ein Tooltip angezeigt wird
   * @param event event.data entspricht der ID des Tooltips
   */
  onToolTipShow(event: TrackingEvent): void {
    // console.debug('Showed tooltip ' + event.data);
  }

  /**
   * Wird aufgerufen wenn ein Tooltip augeblendet wird
   * @param event event.data entspricht der ID des Tooltips
   */
  onToolTipHide(event: TrackingEvent): void {
    // console.debug('Hide tooltip ' + event.data);
  }

  /**
   * Wird aufgerufen, wenn ein Wert geändert wurde
   * @param event event.data.id entspricht der ID des Eingabefeldes. event.data.value entspricht dem neuen Wert.
   */
  onValueChange(event: TrackingEvent): void {
    // console.debug('Changed value of '' + event.data.id + '' to ' + event.data.value);
  }

}
