import { Injectable } from '@angular/core';
import { TrackingEvent } from '../classes/events/tracking-event';
import { ConfigService } from './config.service';
import { PriceUtils } from '../utils/price-utils';

declare const ga: any;

const ROUND_TO = 500;
const RATE_ROUND_TO = 100;

@Injectable()
export class TrackingService {

  private ga: any;

  private wasVisible: boolean;


  constructor(public configService: ConfigService) {
    this.loadScript();
  }


  loadScript(): void {
    // Site ID not set => disable tracking
    if (!this.configService.config.analyticsTrackingId) {
      return;
    }

    // Load Google Analytics
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = Date.now();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    this.ga = window['ga'] || function () {
      (this.ga.q = this.ga.q || []).push(arguments);
    };
    this.ga.l = +new Date();

    const trackerName = this.configService.config.analyticsTrackerName;

    ga('create', this.configService.config.analyticsTrackingId, 'auto', trackerName);
    ga(`${trackerName}.set`, 'anonymizeIp', true);
    ga('send', 'pageview');
  }


  /**
   * Send an event to Google Analytics
   * @param category The event category
   * @param action The event action
   * @param label The event label
   */
  trackEvent(category: string, action: string, label: string) {
    if (!this.ga) {
      return;
    }

    this.ga(`${this.configService.config.analyticsTrackerName}.send`, {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
  }

  /**
   * Set properties for the current user
   * @param properties The properties to set
   */
  set(properties: any) {
    if (!this.ga) {
      return;
    }

    this.ga(`${this.configService.config.analyticsTrackerName}.set`, properties);
  }


  /**
   * Wird aufgerufen, wenn der Rechner aufgerufen wird
   */
  trackPageView(event: TrackingEvent): void {
    // Page view is tracked in loadScript()
  }

  /**
   * Is called when the visibility has been updated
   * @param visibility Whether the application is completely visible or not
   */
  trackVisibility(visibility: boolean): void {
    // Visibility has already been tracked => ignore
    if (!visibility || this.wasVisible) {
      return;
    }

    this.trackEvent('visible', 'visibilityChange', 'Application is now fully visible');

    this.wasVisible = true;
  }

  /**
   * Wird aufgerufen, wenn eine Berechnung durchgeführt wird
   */
  trackCalculation(event: TrackingEvent): void {
    const properties = {
      dimension1: PriceUtils.round(event.data.carPrice, ROUND_TO),
      dimension2: event.data.desiredCredit,
      dimension3: PriceUtils.round(event.data.balloonAmount, ROUND_TO),
      dimension4: PriceUtils.round(event.data.interestRateEffective, ROUND_TO),
      dimension5: event.data.totalFinanceAmount,
      dimension6: event.data.cliAmount,
      dimension7: PriceUtils.round(event.data.interestRateNominal, ROUND_TO),
      dimension8: event.data.downpayment
    };

    this.set(properties);
  }

  /**
   * Wird aufgerufen, wenn der Drucken-Button geklickt wird
   * @param event
   */
  trackPrintButtonClick(event: TrackingEvent): void {
    this.trackEvent('print', 'printButtonClick', 'Print button has been clicked');
  }

  /**
   * Wird aufgerufen wenn der 'E-Mail senden'-Button geklickt wird
   * @param event
   */
  trackMailButtonClick(event: TrackingEvent): void {
    this.trackEvent('mail', 'mailButtonClick', 'Mail button has been clicked');
  }

  /**
   * Wird aufgerufen wenn ein Tooltip angezeigt wird
   * @param event event.data entspricht der ID des Tooltips
   */
  trackToolTipShow(event: TrackingEvent): void {
    // console.debug('Showed tooltip ' + event.data);
  }

  /**
   * Wird aufgerufen wenn ein Tooltip augeblendet wird
   * @param event event.data entspricht der ID des Tooltips
   */
  trackToolTipHide(event: TrackingEvent): void {
    // console.debug('Hide tooltip ' + event.data);
  }

  /**
   * Wird aufgerufen, wenn ein Wert geändert wurde
   * @param event event.data.id entspricht der ID des Eingabefeldes. event.data.value entspricht dem neuen Wert.
   */
  trackValueChange(event: TrackingEvent): void {
    // console.debug('Changed value of '' + event.data.id + '' to ' + event.data.value);
  }

}
