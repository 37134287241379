import { Observable, range as observableRange, Subscriber, timer as observableTimer } from 'rxjs';

import { map, mergeMap, retryWhen, zip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { Error } from '../classes/error';
import { LoginResponse } from '../classes/responses/login-response';
import { RatenrechnerComponent } from '../ratenrechner.component';
import { ConfigService } from './config.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from './jwt.service';

const TOKEN_EXP_OFFSET = 10;

@Injectable()
export class AuthorizationService {

  constructor(public http: HttpClient,
              public translate: TranslateService,
              public errorService: ErrorService,
              public configService: ConfigService,
              public jwtHelper: JwtHelperService) {}


  // Authorization

  /**
   * Führt die Autorisierung am Serviceprovider durch.
   */
  authorize(): Observable<LoginResponse> {
    return new Observable<LoginResponse>((observer: Subscriber<LoginResponse>) => {
      const body = {
        originUrl: window.location.toString()
      };

      //add dealerID in AuthRequest
      let customHeaders = new HttpHeaders();
      if(this.configService.config.dealerID){
        customHeaders = new HttpHeaders().set('DealerID', this.configService.config.dealerID);
      }

      // this.http.post('http://127.0.0.1:8080/auth/anonymous',
      this.http.post(this.configService.config.urls.auth, body, {headers: customHeaders}).pipe(
        map(res => new LoginResponse(res)),retryWhen(attempts =>
          attempts.pipe(zip(observableRange(1, 10), (_, i) => i),mergeMap(i => {
            RatenrechnerComponent.setServerOnline(false);
            console.log('Verbindung zum Server gescheitert. Neuer Versuch in ' + i + ' Sekunden');
            return observableTimer(i * 1000);
          }),)),)
        .subscribe(
          (data: LoginResponse) => {
            if (this.handleErrors(data)) {
              // console.log('FAILED AUTHENTICATION: ' + data.error.errorCode);
              observer.error(data);
            } else {
              RatenrechnerComponent.setServerOnline(true);
              // this._translate.reloadLang(this._translate.getBrowserLang());
              // this._translate.use(this._translate.getBrowserLang());
              // console.log('AUTHENTICATION: ' + JSON.stringify(data));
              this.token = data.token;
              observer.next(data);
            }
          },
          (err) => {
            this.errorService.error(this.errorService.connectionError());
            observer.error();
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  isTokenValid(): boolean {
    return !this.jwtHelper.isTokenExpired(this.token, TOKEN_EXP_OFFSET);
  }

  decodeToken(jwt: string): any {
    return this.jwtHelper.decodeToken(jwt);
  }


  // Error Handling

  /**
   * Prüft ob ein Fehler beim Login aufgetreten ist.
   * @param result Das Login-Ergebnis, das vom Serviceprovider kommt
   * @returns true, falls Fehler aufgetreten sind, false, falls nicht
   */
  handleErrors(result: LoginResponse): boolean {
    // No errors existing
    if (!result.error || !result.error.errorCode) {
      this.errorService.clearErrors();
      return false;
    }

    const error: Error = new Error(result.error.errorCode, result.error.errorMessage);
    this.errorService.error(error);

    return true;
  }


  get token() {
    return localStorage.getItem('token');
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get authorizationHeader() {
    return 'Bearer ' + this.token;
  }

}
