import { CalculationRequest } from '../../classes/requests/calculation-request';
import { CalculationService } from '../../services/calculation.service';
import { CookieHandleService } from '../../services/cookie-handle.service';
import { ErrorService } from '../../services/error.service';
import { PdfService } from '../../services/pdf.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../../services/config.service';
import { Component, Input, OnInit } from '@angular/core';
import { TrackingService } from '../../services/tracking.service';
import { EventType } from '../../constants/event-type';
import { TrackingEvent } from '../../classes/events/tracking-event';

@Component({
    selector: 'toybr-calculation-form',
    templateUrl: './calculation-form.component.html',
    styleUrls: ['./calculation-form.component.scss', '../../styles/styles.scss'],
    animations: [
        trigger('calculatingState', [
            state('inactive', style({
                // backgroundColor: '#eee',
                opacity: '1',
            })),
            state('active', style({
                // backgroundColor: '#cfd8dc',
                opacity: '0',
            })),
            transition('inactive => active', animate('150ms ease-in')),
            transition('active => inactive', animate('500ms ease-out'))
        ])]
})
export class CalculationFormComponent implements OnInit {

    @Input() servOn: boolean;
    @Input() showSearch: boolean;
    @Input() carPriceBasis: number;
    @Input() carPriceInterval: number;
    readonly POSSIBLE_TERMS = [12, 24, 36, 48, 60];
    calculatingState = 'inactive';
    request: CalculationRequest;
    inputForm: FormGroup;
    readonly REGEX = '^(?:\\d+|([0-9]{1,3})(?:\\.\\d{3})+)(?:\\,\\d+)*$';
    readonly POSSIBLE_MILEAGE: { key: string, value: number }[] = [{
        key: '10.000',
        value: 10000
    }, {key: '15.000', value: 15000}, {key: '20.000', value: 20000}, {key: '25.000', value: 25000}, {
        key: '30.000',
        value: 30000
    }, {key: '35.000', value: 35000}, {key: '40.000', value: 40000}, {key: '45.000', value: 45000}, {
        key: '50.000',
        value: 50000
    }, {key: '55.000', value: 55000}, {key: '60.000', value: 60000}, {key: '65.000', value: 65000}, {
        key: '70.000',
        value: 70000
    }, {key: '75.000', value: 75000}, {key: '80.000', value: 80000}, {key: '85.000', value: 85000}, {
        key: '90.000',
        value: 90000
    }, {key: '95.000', value: 95000}, {key: '100.000', value: 100000}];
    showCalcLoader = false;
    showEmailDialog = false;

    constructor(
        public calculationService: CalculationService,
        public formBuilder: FormBuilder,
        public errorService: ErrorService,
        public pdfService: PdfService,
        public cookieHandleService: CookieHandleService,
        public configService: ConfigService,
        public trackingService: TrackingService
    ) {}


    ngOnInit(): void {
        // Lädt Request aus dem cookie wenn vorhanden
        if (this.cookieHandleService.isPresent(this.configService.config.cookieName)) {
            this.request = this.cookieHandleService.loadCookieAsCalculationRequest(this.configService.config.cookieName);
            this.calculationService.lastRequest = this.request;
        } else {
            this.request = CalculationRequest.default();
        }

        this.inputForm = this.formBuilder.group({
            instalment: [this.request.instalment, Validators.pattern(this.REGEX)],
            downpayment: [this.request.downpayment, Validators.pattern(this.REGEX)],
            term: [this.request.term],
            cliRequest: [this.request.cliRequest],
            balloonRequest: [this.request.balloonRequest],
            mileage: [this.request.mileage]
        });

        // Setzt die angezeigt Werte bei Änderung im Service neu
        this.calculationService.requestChanged.subscribe((request: any) => {
            this.request = request;
        });

        this.setupTracking();
    }


    setupTracking(): void {
        for (const control in this.inputForm.controls) {
            this.inputForm.controls[control].valueChanges.subscribe(
                (data) => {
                    this.trackingService.trackValueChange(
                        new TrackingEvent(EventType.VALUE_CHANGE, {id: control, value: data})
                    );
                }
            );
        }
    }


    /**
     * Stößt die Berechnung mit den eingegeben Werten an
     */
    onSubmit() {
        this.calculatingState = 'active';
        // console.log("Animation " + this.calculatingState);
        if (this.inputForm.valid) {
            if (!this.request.equals(this.calculationService.lastSubmittedRequest)) {
                this.showCalcLoader = true;
                this.calculationService.getCalculationResult(this.request).subscribe(
                    (res) => {
                    },
                    (err) => {
                        this.showCalcLoader = false;
                        this.calculatingState = 'inactive';
                        // console.log("Animation " + this.calculatingState);
                    },
                    () => {
                        this.showCalcLoader = false;
                        this.calculatingState = 'inactive';
                        // console.log("Animation " + this.calculatingState);
                    }
                );
                this.calculationService.lastSubmittedRequest = CalculationRequest.fromData(this.request);
            } else {
                this.calculatingState = 'inactive';
            }
        }
    }

    /**
     * Setzt die Laufzeit auf einen angegebenen Wert.
     * Wird ausgeführt, wenn die ausgewählte Laufzeit sich ändert.
     * @param term Laufzeit in Monaten
     */
    onTermChange(term: number) {
        this.request.term = term;
    }

    /**
     * Setzt die Kilometerleistung auf den gewählten Wert.
     * @param newValue Kilometerleistung
     */
    onMileAgeChange(newValue: any) {
        this.request.mileage = newValue;
    }

    generatePdf(): void {
        // Track print button click
        this.trackingService.trackPrintButtonClick(
            new TrackingEvent(EventType.CLICK_PRINT)
        );

        let url: string = this.pdfService.generatePdf(
            this.calculationService.lastRequest,
            this.calculationService.lastResponse,
            'de'
        );
        window.open(url, '_blank');
    }

    goToMatchingCars(): void{
        if (typeof window['showMatchingCars'] !== 'function') {
            console.log('could not find function showMatchingCars(priceFrom, priceTo)');
            return;
        }
        let priceFrom: number;
        let priceTo: number;
        let steps = Math.floor(this.calculationService.lastResponse.carPrice / this.carPriceBasis);

        priceFrom = Number(this.carPriceBasis * steps);
        priceTo = priceFrom + Number(this.carPriceInterval);

        window['showMatchingCars'](priceFrom, priceTo);
    }

}
