import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalculationResponse } from '../../classes/responses/calculation-response';
import { CalculationService } from '../../shared/calculation.service';
import { ErrorService } from '../../shared/error.service';
import { CalculationRequest } from '../../classes/requests/calculation-request';
import { PdfService } from '../../shared/pdf.service';
import { TrackingService } from '../../shared/tracking.service';
import { EventType } from '../../constants/event-type';
import { TrackingEvent } from '../../classes/events/tracking-event';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'toyrr-bulk-calculation-output-form',
    templateUrl: './bulk-calculation-output-form.component.html',
    styleUrls: ['./bulk-calculation-output-form.component.scss', '../../styles/styles.scss']
})
export class BulkCalculationOutputFormComponent implements OnInit {

    @Output() calculate: EventEmitter<CalculationResponse> = new EventEmitter<CalculationResponse>();

    calculationRequest: CalculationRequest = new CalculationRequest();
    calculationResponse: CalculationResponse = new CalculationResponse();
    @Input() loading: boolean;
    @Input() inputFormValid: boolean;

    showEmailDialog: boolean;

    constructor(
        public calculationService: CalculationService,
        public errorService: ErrorService,
        public pdfService: PdfService,
        public trackingService: TrackingService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.calculationService.requestChanged
            .subscribe(response => {
                this.calculationRequest = response;
            });

        this.calculationService.calculationChanged
            .subscribe(response => {
                this.calculationResponse = response;
                this.calculate.emit(response);
            });
    }


    generatePdf(): void {
        // Tracking
        this.trackingService.printButtonEmitter.emit(new TrackingEvent(EventType.CLICK_PRINT));

        const url: string = this.pdfService.generatePdf(
            this.calculationService.lastRequest,
            this.calculationService.lastResponse,
            this.translate.getBrowserLang()
        );
        window.open(url, '_blank');
    }

}
