import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[myMatchHeight]'
})
export class MatchHeightDirective implements AfterViewChecked {

  // class name to match height
  @Input()
  myMatchHeight: any;
  resizeTimer: any;

  constructor(private el: ElementRef) {
  }

  ngAfterViewChecked() {
    // call our matchHeight function here later
    if (this.el) {
      this.matchHeight(this.el.nativeElement, this.myMatchHeight);
    }
  }

  @HostListener('window:resize')
  onResize() {
    /*
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(function () {
      if (this.el) {
        this.matchHeight(this.el.nativeElement, this.myMatchHeight);
      }
    }, 500);
    */
  }

  matchHeight(parent: HTMLElement, className: string) {
    // match height logic here

    if (!parent) {
      return;
    }
    const children = parent.getElementsByClassName(className);

    if (!children) {
      return;
    }

    // reset all children height

    Array.from(children).forEach((x: HTMLElement) => {
      x.style.minHeight = 'initial';
    });

    // check if flexbox has wrapped (boxes are are arranged among themselves)
    if (parent.getElementsByClassName('output-rr-container')[0].getBoundingClientRect().left <= parent.getElementsByClassName('input-rr-container')[0].getBoundingClientRect().left) {
      parent.getElementsByClassName('calc-output')[0].removeAttribute('style');
      parent.getElementsByClassName('output-title')[0].removeAttribute('style');
      parent.getElementsByClassName('output-rr-container')[0].removeAttribute('style');
      parent.getElementsByClassName('input-rr-container')[0].removeAttribute('style');
      return;
    } else {
      // set min height for input
      parent.getElementsByClassName('input-rr-container')[0].setAttribute('style', `min-height: 510px!important;`);
    }

    // gather all height
    const itemHeights = Array.from(children)
      .map(x => x.getBoundingClientRect().height);

    // find max height
    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    // apply max height
    parent.getElementsByClassName('output-rr-container')[0].setAttribute('style', `min-height: ${maxHeight}px!important;`);

    // get height of calcform-header and set this as margin
    const marginTop = parent.getElementsByClassName('calcform-header')[0].getBoundingClientRect().height;
    parent.getElementsByClassName('output-title')[0].setAttribute('style', `margin-top: ${marginTop}px!important;`);

    // get height of abstand-container-1 and abstand-container-2 and set this as height
    let totalHeight = parent.getElementsByClassName('abstand-container-1')[0].getBoundingClientRect().height + parent.getElementsByClassName('abstand-container-2')[0].getBoundingClientRect().height;

    if (parent.getElementsByClassName('abstand-container-3')[0]) {
      totalHeight += parent.getElementsByClassName('abstand-container-3')[0].getBoundingClientRect().height - 30;
    } else {
      totalHeight += parent.getElementsByClassName('abstand-container-4')[0].getBoundingClientRect().height - 2;
    }

    const outputHeight = parent.getElementsByClassName('calc-output')[0].getBoundingClientRect().height;
    const diffMargin = totalHeight - outputHeight;

    if (diffMargin > 0) {
      parent.getElementsByClassName('calc-output')[0].setAttribute('style', `margin-top: ${diffMargin}px !important;padding-top: 0em !important;`);
    } else {
      parent.getElementsByClassName('calc-output')[0].setAttribute('style', 'margin-top: 0px !important;padding-top: 0em !important;');
    }
  }
}
