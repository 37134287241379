import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomDecimalPipe } from '../../pipes/custom-decimal.pipe';
import { FormattedStringToNumberPipe } from '../../pipes/formatted-string-to-number.pipe';
import { ConfigService } from '../../shared/config.service';

export const CURRENCY_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true
};

@Component({
    selector: 'toyrr-currency-input',
    styleUrls: ['./currency-input.component.scss', '../../styles/styles.scss'],
    template: `
    <label class="input-label">{{label}} <toyrr-tooltip [ttId]="ttId" *ngIf="!!tooltip">{{tooltip}}</toyrr-tooltip></label>
    <div class="currency-input-wrapper">
        <span class="currency-icon">€</span>
        <input #input type="text" class="currency-input {{customStyleClass}}" (keyup)="keyUp($event, input.value)" [disabled]="disable" 
               (change)="writeValue(input.value)" [class.transparent]="transparent" (blur)="onBlur($event, input.value)" 
               [class.error]="configService.config.isPriceDetected && hasError" [value]="rawValue">
    </div>
  `,
    providers: [
        CURRENCY_INPUT_CONTROL_VALUE_ACCESSOR
    ]
})
export class CurrencyInputComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() transparent: boolean;
    @Input() disable: boolean;
    @Input() tooltip: string;
    @Input() ttId: string;
    @Input() hasError: boolean;
    @Input() hideDecimal: boolean;
    @Input() customStyleClass: string

    @Output() inputChangeReady: EventEmitter<any> = new EventEmitter<any>();
    @Output() change: EventEmitter<string> = new EventEmitter<string>();
    @Output() blur: EventEmitter<string> = new EventEmitter<string>();

    inputReady: Subject<any> = new Subject();

    value: string;
    rawValue = '';

    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;


    constructor(public customDecimal: CustomDecimalPipe,
                public formattedStringToNumber: FormattedStringToNumberPipe,
                public configService: ConfigService) {
      this.inputChangeReady = this.inputReady.asObservable()
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged()) as any;
    }


    writeValue(obj: any): void {
      const unformatted = this.formattedStringToNumber.transform(obj);

        if (unformatted == null) {
            return;
        }

        let decimalPlaces = 2;
        if(this.hideDecimal)
          decimalPlaces = 0;

      const formatted = this.customDecimal.transform(unformatted, decimalPlaces, ',', '.', '');
        this.rawValue = formatted;
        this.onChangeCallback(formatted);
        this.change.emit(this.rawValue);
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    keyUp(event: any, obj: any) {
        // console.log("TasteNr: " + event.code + " oder " + event.which);
        if (/*event.which === 9 || // TAB
            event.which === 8 || // Backspace
            event.which === 46 || // ENTF*/
            event.which === 27 || // ESC
            event.which === 37 || // ARROWS
            event.which === 38 || // ARROWS
            event.which === 39 || // ARROWS
            event.which === 40 // ARROWS
        ) {
            return;
        }

        this.rawValue = obj;
        this.onChangeCallback(obj);
        this.inputReady.next(event);
    }

    onBlur(event: any, obj: any) {
        this.keyUp(event, obj);

        let unformatted = this.formattedStringToNumber.transform(obj);

        if (unformatted == null) {
            unformatted = 0;
        }

      let decimalPlaces = 2;
      if(this.hideDecimal)
        decimalPlaces = 0;

      const formatted = this.customDecimal.transform(unformatted, decimalPlaces, ',', '.', '');
        this.rawValue = formatted;
        this.onChangeCallback(formatted);
        this.blur.emit(obj);
    }
}
