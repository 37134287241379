export class ErrorCode {

  public static WS_NO_CONNECTION = 'WS_NO_CONNECTION';
  public static WS_GENERAL_ERROR = 'WS_GENERAL_ERROR';
  public static WS_NOT_AUTHORIZED = 'WS_NOT_AUTHORIZED';

  public static CALCULATION_ERRORS: string[] = [
    ErrorCode.WS_NO_CONNECTION,
    ErrorCode.WS_GENERAL_ERROR,
    ErrorCode.WS_NOT_AUTHORIZED
  ];

}
