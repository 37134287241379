export class EmailRequest {


    public emailAddress: string;
    public locale: string;

    constructor() {
    }

}
