import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minValue(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        let input = control.value;
      const comma = /,/g;
      const dot = /\./g;
        input = String(input);
        input = input.replace(dot, '');
        input = input.replace(comma, '.');
      const isValid = input < min;
        if (isValid) {
          return {minValue: {min}};
        } else {
            return null;
        }
    };
}
