import { Component, Input } from '@angular/core';
import { Error } from '../../classes/error';

@Component({
  selector: 'toybr-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss', '../../styles/styles.scss']
})
export class ErrorBoxComponent {

  @Input() error: Error;

  constructor() {}

  /**
   * Prüft ob die Anwendung sich im produktiven Betrieb befindet.
   * @returns true, falls der Produktiv-Betrieb aktiviert ist, false, falls nicht.
   */
  isDebug(): boolean {
    return false;
  }

}
