import { Injectable } from '@angular/core';
import { CalculationService } from './calculation.service';
import { Observable } from 'rxjs';

@Injectable()
export class ParentScannerService {

    calcService: CalculationService;

    constructor(calcService: CalculationService) {
        this.calcService = calcService;
    }


    // Price detection

    /**
     * Sucht nach einem Element, das einen gültigen Preis enthält und formatiert ihn.
     * @param id Die ID, mit der ein Preis-Element gesucht werden soll
     * @param clazz Die Klasse, mit der ein Preis-Element gesucht werden soll
     * @param attribute Der Name des Attributs, mit dem ein Preis-Element gesucht werden soll
     * @param currencySign Das erwartete Währungszeichen (z.B. '€')
     * @param germanNotation Wenn dies true ist, wird die folgende Formatierung erwartet: '#.###,##'. Ansonsten: '#,###.##'
     */
    findPrice(id: string, clazz: string, attribute: string, currencySign: string, germanNotation: boolean): Observable<number> {
        return new Observable<number>(observer => {
            const price =
                this.findPriceByNearestElement('[id*="' + id + '"]', currencySign, germanNotation) ||
                this.findPriceByNearestElement('[class*="' + clazz + '"]', currencySign, germanNotation) ||
                this.findPriceByNearestElement('[' + attribute + ']', currencySign, germanNotation);

            if (!price) {
                observer.error();
            } else {
                observer.next(price);
            }

            observer.complete();
        });
    }


    findPriceByNearestElement(selector: string, currencySign: string, germanNotation: boolean): number {
      const orientationElement: HTMLElement = document.getElementById('rr-container');

        // Alle als class='price-quantity' deklarierten Elemente im DOM befinden sich in der priceElementList.
      const priceElementList: NodeListOf<Element> = document.querySelectorAll(selector);
        // Array für die Abstände wird angelegt.
      const distanceList = [];
        // Die metrischen Daten des Elements, welches die Funktion aufruft, werden gespeichert
        // metrische Daten = Abstand top, bottom, right und left des Elementes zum sichtbaren Fenster des Clients
      const callingElement = orientationElement.getBoundingClientRect();

        // Funktion, die ein übergebenes Array auf den min value durchsucht und diesen dann zurückgibt.
      const ArrayMinValue = (array) => {
            return Math.min.apply(Math, array);
        };

        /*
         Über die gefunden Elemente wird iteriert und die Abstände (window.top) zwischen dem Element,
         welches die Funktion aufruft und dem Element, welches
         in der priceElementList steht, werden ermittelt und in der distanceList gespeichert
         */

        for (let i = 0; i < priceElementList.length; i++) {
            // For test purpose
            // priceElementList[i].style.backgroundColor = 'red';
            // elem.style.backgroundColor = 'red';

          const detectedElement = priceElementList.item(i).getBoundingClientRect();
          const distance = detectedElement.top - callingElement.top;
            distanceList.push(Math.abs(distance));
        }

        // Das Element mit dem geringsten Abstand wird ermittelt. Danach wird der jeweilige Preis in das Inputfield übernommen
      const minValue = ArrayMinValue(distanceList);
      const index = distanceList.indexOf(minValue);

        // For test purpose
        // priceElementList.item(index).style.backgroundColor = 'green';

      const minValueElement: Element = priceElementList.item(index);

        // No element or valid price found
        if (!minValueElement || !this.isValidPrice(minValueElement.textContent, currencySign, germanNotation)) {
            return null;
        }

        return this.convertToPrice(minValueElement.textContent, currencySign, germanNotation);
    }


    // Validation

    isValidPrice(str: string, currencySign: string, germanNotation: boolean): boolean {
        let price: string = str;

        if (str.indexOf(currencySign) !== -1) {
            price = price.replace(currencySign, '');
        }

        return this.isValidNumber(str.replace(currencySign, ''), germanNotation);
    }

    isValidNumber(str: string, germanNotation: boolean): boolean {
        let unformatted = str;

        // Convert number to valid format
        if (germanNotation) {
            unformatted = unformatted.replace('.', '').replace(',', '.');
        }

        return !isNaN(Number.parseFloat(unformatted));
    }

    convertToPrice(str: string, currencySign: string, germanNotation: boolean): number {
        let unformatted = str;

        unformatted = unformatted.replace(currencySign, '');

        if (germanNotation) {
            unformatted = unformatted.replace('.', '').replace(',', '.');
        }

        return Number.parseFloat(unformatted);
    }

}
