export class CalculationRequest {

    public instalment: any;
    public term: number;
    public downpayment: any;
    public balloonRequest: boolean;
    public cliRequest: boolean;
    public interestRate: number;
    public sessionID: number;
    public customerID: number;
    public mileage = 25000;


    /**
     * Factory method to create new CalculationRequest from existing data
     * @param data Already existing CalculationRequest data
     * @returns Instance of CalculationRequest with the given data
     */
    static fromData(data: any): CalculationRequest {
        let req = new CalculationRequest();

        req.instalment = data.instalment;
        req.term = data.term;
        req.downpayment = data.downpayment;
        req.balloonRequest = data.balloonRequest;
        req.cliRequest = data.cliRequest;
        req.interestRate = data.interestRate;
        req.sessionID = data.sessionID;
        req.customerID = data.customerID;
        req.mileage = data.mileage;

        return req;
    }

    /**
     * Bereitet die Request Daten für die Speicherung im Cookie vor.
     * - Interestrate wird nicht gespeichert
     * @param originalRequest
     * @returns
     */
    static forCache(originalRequest: CalculationRequest): CalculationRequest {
        let req = new CalculationRequest();
        req.instalment = originalRequest.instalment;
        req.term = originalRequest.term;
        req.downpayment = originalRequest.downpayment;
        req.balloonRequest = originalRequest.balloonRequest;
        req.cliRequest = originalRequest.cliRequest;
        req.sessionID = originalRequest.sessionID;
        req.customerID = originalRequest.customerID;
        req.mileage = originalRequest.mileage;

        return req;
    }

    static default(): CalculationRequest {
        return CalculationRequest.fromData({
            instalment: '151,24',
            term: 48,
            downpayment: '3000,00',
            balloonRequest: false,
            cliRequest: false,
            interestRate: 3.99,
            sessionID: 123,
            customerID: 456,
            mileage: 25000
        });
    }

    /**
     * Vergleicht die Attribute des aufrufenden CalculationRequests mit den Attributen des übergebenen auf Datengleichheit, NICHT auf Typgleichheit
     * @param request der zu vergleichende CalculationRequest
     * @returns true, wenn die Attribute bzgl. des Wertes gleich sind (auch, wenn die Datentypen unterschiedlich sind. Beispiel: Vergleich 3 und '3' => true).
     * false, wenn sich der Wert mindestens eines Attributes unterscheidet.
     */
    public equals(request: CalculationRequest) {
        if (!request.instalment
            || !request.term
            || !request.downpayment
            || !request.balloonRequest
            || !request.cliRequest
            || !request.interestRate
            || !request.sessionID
            || !request.customerID
            || !request.mileage) {
            return false;
        }
        return !(this.instalment !== request.instalment
        || this.term !== request.term
        || this.downpayment !== request.downpayment
        || this.balloonRequest !== request.balloonRequest
        || this.cliRequest !== request.cliRequest
        || this.interestRate !== request.interestRate
        || this.sessionID !== request.sessionID
        || this.customerID !== request.customerID
        || this.mileage !== request.mileage);
    }

    /**
     * Kopiert alle Eigenschaften in einen anderen CalculationRequest.
     * @param req Der Request, in den die Eigenschaften kopiert werden sollen
     * @returns true, falls sich beim Kopieren im Zielobjekt ein Wert geändert hat, false, falls nicht
     */
    public copyTo(req: CalculationRequest): boolean {
        let hasChanged: boolean = false;

        let properties: string[] = Object.keys(req);
        for (let i = 0; i < properties.length; i++) {
            if (req[properties[i]] !== this[properties[i]]) {
                req[properties[i]] = this[properties[i]];
                hasChanged = true;
            }
        }

        return hasChanged;
    }

}
