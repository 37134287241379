import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailRequest } from '../classes/requests/email-request';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { ErrorService } from './error.service';
import { Error } from '../classes/error';
import * as SHA2 from '../classes/hash/sha2';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class EmailService {

    error: Error;

    constructor(public http: HttpClient,
                public errorService: ErrorService,
                public formattetStringToNumber: FormattedStringToNumberPipe,
                public configService: ConfigService) {}

    /**
     * Email Service
     */
    sendMail(request: EmailRequest, calculationRequest: CalculationRequest): Observable<string> {
      return new Observable<string>(observer => {
            let params = new HttpParams();

            // Calculation Request
            params = params.set('productID', calculationRequest.productID);
            params = params.set('term', calculationRequest.term);
            params = params.set('downpayment', this.formattetStringToNumber.transform(calculationRequest.downpayment));
            params = params.set('balloonAmount', this.formattetStringToNumber.transform(calculationRequest.balloonAmount));
            params = params.set('carPrice', this.formattetStringToNumber.transform(calculationRequest.carPrice));
            params = params.set('mileage', this.formattetStringToNumber.transform(calculationRequest.mileage));
            params = params.set('cliRequested', String(calculationRequest.cliRequested));
            params = params.set('interestRate', calculationRequest.interestRate);
            params = params.set('carType', calculationRequest.carType);

            // Email Request
            params = params.set('email', request.emailAddress);
            // Locale
            params = params.set('language', request.locale);

            let scrambleString = "" + params.get('productID')
              + params.get('term')
              + params.get('downpayment')
              + params.get('balloonAmount')
              + params.get('cliRequested')
              + params.get('interestRate')
              + params.get('carPrice')
              + params.get('carType')
              + params.get('mileage')
              + params.get('email')
              + this.formattedDate(new Date());

            params = params.set('scramble', SHA2.SHA2_256(scrambleString));

            this.http.get(this.configService.config.urls.email, { params })
                .subscribe(
                    data => {
                        // console.log('Successfully send' + data);
                        observer.next();
                        observer.complete();
                    },
                    err => {
                        console.log('E-Mail ERROR ' + err);
                        this.error = err.json().error;
                        this.handleErrors(this.error);
                        observer.error(err);
                        observer.complete();
                    });
        });

    }

    // No errors existing
    handleErrors(result: Error): boolean {
        if (!result) {
            this.errorService.clearErrors();
            return false;
        }

      const error: Error = new Error(result.errorCode, result.errorMessage);
        this.errorService.error(error);

        return true;
    }

    formattedDate(date: Date): string {
      const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
      const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      const stringDate: string = days[date.getDate() - 1] + '.' + months[date.getMonth()] + '.' + date.getFullYear();
        return stringDate;
    }

}
