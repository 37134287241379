import { Injectable } from '@angular/core';
import { BudgetrechnerConfig } from '../config/budgetrechner-config';

@Injectable()
export class ConfigService {

  _config: BudgetrechnerConfig;


  constructor(customConfig?: BudgetrechnerConfig) {
    this.config = {
      defaultLanguage: 'de',
      urls: {
        i18n: 'http://serviceprovider-test.itsd-consulting.com/language',
        auth: 'http://serviceprovider-test.itsd-consulting.com/auth/anonymous',
        calculation: 'http://serviceprovider-test.itsd-consulting.com/api/v1/calculateBudget',
        email: 'http://serviceprovider-test.itsd-consulting.com/api/v1/sendBudgetMail',
        pdfBudget: 'http://serviceprovider-test.itsd-consulting.com/pdfBudget',
        econdaLib: 'http://serviceprovider-test.itsd-consulting.com/js/emos3.js',
        analyticsTrackingId: '',
        analyticsTrackerName: ''
      },
      econdaSiteId: 'TFS-UAT',
      cookieName: 'budgetRequestCookie',
      analyticsTrackingId: '',
      analyticsTrackerName: ''
    };

    if (customConfig != null) {
      this.config = customConfig;
    }

    // console.log('LOADED CONFIG: ' + JSON.stringify(this.config));
  }


  get config() {
    return this._config;
  }

  set config(config: BudgetrechnerConfig) {
    if (!config) {
      return;
    }

    if (!this._config) {
      this._config = config;
    }

    Object.keys(config).forEach((key: string) => {
      // Nicht gesetzte Werte nicht übernehmen
      if (config[key] == null) {
        return;
      }

      this._config[key] = config[key];
    });
  }

}
