import { RatenrechnerUrlConfig } from './ratenrechner-url-config';

export class RatenrechnerConfig {

    defaultLanguage?: string;
    urls?: RatenrechnerUrlConfig;
    productServiceFilter?: string;
    translateLoaderFactory?: Function;
    analyticsTrackingId?: string;
    analyticsTrackerName?: string;
    googleTagMangerCode?: string;

    minCarPrice?: number;
    maxCarPrice?: number;

    isPriceDetected?: boolean;
    wasEmptyLoaded?: boolean;
    tableAlreadyLoaded?: boolean;

    dealerID?: string;

    reqCookieName?: string;
    bulkReqCookieName?: string;
    reqCookieDuration?: number;
    bulkReqCookieDuration?: number;

}
