import { EventEmitter, Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CalculationResponse } from '../classes/responses/calculation-response';
import { Error } from '../classes/error';
import { Observable } from 'rxjs';
import { ErrorCode } from '../constants/error-code';
import { CookieHandleService } from './cookie-handle.service';
import { CustomDecimalPipe } from '../pipes/custom-decimal.pipe';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from './tracking.service';
import { EventType } from '../constants/event-type';
import { TrackingEvent } from '../classes/events/tracking-event';
import { map } from 'rxjs/operators';

@Injectable()
export class CalculationService {

  _requestChanged = new EventEmitter<CalculationRequest>();
  _calculationChanged = new EventEmitter<CalculationResponse>();

  _lastRequest: CalculationRequest = CalculationRequest.default();
  _lastSubmittedRequest: CalculationRequest = CalculationRequest.fromData(this._lastRequest);
  _lastResponse: CalculationResponse = new CalculationResponse();

  _readyForCalc = true;

  constructor(
    public errorService: ErrorService,
    public http: HttpClient,
    public formatter: FormattedStringToNumberPipe,
    public customDecimal: CustomDecimalPipe,
    public translate: TranslateService,
    public cookieHandleService: CookieHandleService,
    public configService: ConfigService,
    public trackingService: TrackingService
  ) {
  }


  // Get EventEmitters

  get requestChanged(): EventEmitter<CalculationRequest> {
    return this._requestChanged;
  }

  get calculationChanged(): EventEmitter<CalculationResponse> {
    return this._calculationChanged;
  }


  // Check/Set if service is ready for calculation

  /**
   * Legt fest, ob die Anwendung bereit für eine Berechnung ist.
   * @param readyForCalc true, falls die Anwendung bereit für eine Berechnung ist, false, falls nicht
   */
  public setReadyForCalc(readyForCalc: boolean): void {
    this._readyForCalc = readyForCalc;
  }

  /**
   * Prüft ob die Anwendung bereit für eine Berechnung ist.
   * @returns true, falls die Anwendung bereit für eine Berechnung ist, false, falls nicht
   */
  public isReadyForCalc(): boolean {
    return this._readyForCalc;
  }

  // Calculations

  /**
   * Führt eine Berechnung aus.
   * @param request Werte, mit denen die Berechnung ausgeführt werden soll
   */
  public getCalculationResult(request: CalculationRequest): Observable<CalculationResponse> {
    return new Observable<CalculationResponse>(observer => {
      let params = new HttpParams();
      params = params.set('instalment', this.formatter.transform(request.instalment));
      request.instalment = this.formatRawNumber(request.instalment);
      params = params.set('term', String(request.term));
      params = params.set('downpayment', this.formatter.transform(request.downpayment));
      request.downpayment = this.formatRawNumber(request.downpayment);
      params = params.set('balloonRequest', String(request.balloonRequest));
      params = params.set('cliRequest', String(request.cliRequest));
      params = params.set('interestRate', String(request.interestRate));
      params = params.set('customerID', String(request.customerID));
      params = params.set('sessionID', String(request.sessionID));
      params = params.set('mileage', String(request.mileage));

      this.http.get(this.configService.config.urls.calculation, {params})
        .pipe(
          map(res => CalculationResponse.fromData(res))
        )
        .subscribe(
          (data: CalculationResponse) => {
            if (this.handleErrors(data)) {
              console.log('ERROR: ' + JSON.stringify(data.error));
              console.log('ERRORS: ' + JSON.stringify(this.errorService.errors));
            } else {
              this.lastRequest = request;
              this.lastResponse = data;
              // console.log('CALCULATION:\n' + JSON.stringify(data));
              this.errorService.okAll(ErrorCode.CALCULATION_ERRORS);
              this.cookieHandleService.saveCookie(
                this.configService.config.cookieName, CalculationRequest.forCache(request), 14
              );

              // Track calculation
              this.trackingService.trackCalculation(new TrackingEvent(
                EventType.CALCULATION,
                data
              ));
            }

            observer.next(data);
            observer.complete();
          },
          (err) => {
            if (err._body) {
              const errors = JSON.parse(err._body).errors;
              if (errors) {
                const code = errors[0].defaultMessage;
                if (code === 'MAXIMUM_INSTALMENT_VIOLATION') {
                  this.errorService.error(new Error(code, this.translate.instant('app.error.instalment_max')));
                }
              }
            } else {
              // console.log('Data: ' + JSON.stringify(err));
              this.errorService.error(this.errorService.generalError());
            }
            observer.error();
            observer.complete();
          }
        );
    });

  }

  /**
   * Fromatierung der Inputfelder nach einem Submit
   */
  public formatRawNumber(formattedValue: any): string {
    formattedValue = this.formatter.transform(formattedValue);
    return this.customDecimal.transform(formattedValue, 2, ',', '.', '');
  }

  /**
   * Prüft ob ein Fehler bei der Berechnung aufgetreten ist.
   * @param result Das Berechnungs-Ergebnis, das vom Serviceprovider kommt
   * @returns true, falls Fehler aufgetreten sind, false, falls nicht
   */
  public handleErrors(result: CalculationResponse): boolean {
    // No errors existing
    if (!result.error) {
      this.errorService.clearErrors();
      return false;
    }

    const error = new Error(result.error.errorCode, result.error.errorMessage);
    this.errorService.okAll([result.error.errorCode.toString()]);
    this.errorService.error(error);


    return true;
  }

  // Getters / Setters

  get lastSubmittedRequest(): CalculationRequest {
    return this._lastSubmittedRequest;
  }

  set lastSubmittedRequest(request: CalculationRequest) {
    this._lastSubmittedRequest = request;
  }

  get lastRequest(): CalculationRequest {
    this._requestChanged.emit(this._lastRequest);
    return this._lastRequest;
  }

  set lastRequest(value: CalculationRequest) {
    this._lastRequest = value;
    this._requestChanged.emit(this.lastRequest);
  }

  get lastResponse(): CalculationResponse {
    return this._lastResponse;
  }

  set lastResponse(value: CalculationResponse) {
    this._lastResponse = value;
    this.calculationChanged.emit(this.lastResponse);
  }

}
