import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculationResponse } from '../../classes/responses/calculation-response';

@Component({
    selector: 'toyrr-result-select',
    styleUrls: ['./result-select.component.scss', '../../styles/styles.scss'],
    template: `
      <h3>{{headline}} <toyrr-tooltip>{{headlineTooltip}}</toyrr-tooltip></h3>
      <div class="result-select-headline result-select-line result-flex-wrapper">
        <span class="result-flex-wrapper">
          <span class="result-select-line-item">{{ 'bulk-calculation-form.instalment' | translate }}</span>
          <span class="result-select-line-item">{{ 'bulk-calculation-form.term' | translate }}</span>
          <span class="result-select-line-item">{{ 'bulk-calculation-form.balloonAmount' | translate }}</span>
          <span class="result-select-line-item">{{ 'bulk-calculation-form.interestRateEffective' | translate }}</span>
        </span>
      </div>
      <span class="result-wrapper" *ngFor="let result of results; let i = index">
        <input type="radio" id="{{resultSelectGroup + i}}" name="resultSelectGroup" value="{{resultSelectGroup + i}}" >

        <label class="result-select-line" for="{{resultSelectGroup + i}}" id="{{resultSelectGroup + i}}label" (click)="onSelectResult(result, i)">
          <span class="result-flex-wrapper">
            <span class="result-select-line-item result-select-line-item-strong">{{result.instalment | customDecimal:2:',':'.'}} €</span>
            <span class="result-select-line-item">{{termsArray[i]}} {{ 'bulk-calculation-form.month' | translate }}</span>
            <span *ngIf="result.balloonAmount" class="result-select-line-item">{{result.balloonAmount | number:'1.0-0'}} €</span>
            <span *ngIf="!result.balloonAmount" class="result-select-line-item">{{0 | number:'1.0-0'}} €</span>
            <span class="result-select-line-item">{{result.interestRateEffective | customDecimal:2:',':'.'}} %</span>
          </span>
        </label>
      </span>
    `
})
export class ResultSelectComponent{

    @Input() headline: string;
    @Input() headlineTooltip: string;
    @Input() results: CalculationResponse[];
    @Input() resultSelectGroup: string;
    @Input() termsArray: string[];

    @Output() resultSelected: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    onSelectResult(selectedResult: CalculationResponse, pIndex: number){
      this.resultSelected.emit({selectedResult: selectedResult, index: pIndex});
    }


}
