export class ErrorCode {

    public static WS_NO_CONNECTION = 'WS_NO_CONNECTION';
    public static WS_GENERAL_ERROR = 'WS_GENERAL_ERROR';
    public static WS_NOT_AUTHORIZED = 'WS_NOT_AUTHORIZED';

    public static CAR_PRICE_INVALID_PATTERN = 'CAR_PRICE_INVALID_PATTERN';
    public static CAR_PRICE_LESS_THEN_MINIMUM = 'CAR_PRICE_LESS_THEN_MINIMUM';
    public static CAR_PRICE_EXCEEDED_MAX_VALUE = 'CAR_PRICE_EXCEEDED_MAX_VALUE';

    public static DOWNPAYMENT_INVALID = 'DOWNPAYMENT_INVALID';

    public static BALLOON_AMOUNT_INVALID = 'BALLOON_AMOUNT_INVALID';

    public static CALCULATION_ERRORS: string[] = [
        ErrorCode.WS_NO_CONNECTION,
        ErrorCode.WS_GENERAL_ERROR,
        ErrorCode.WS_NOT_AUTHORIZED,
        ErrorCode.CAR_PRICE_INVALID_PATTERN,
        ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM,
        ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE,
        ErrorCode.DOWNPAYMENT_INVALID,
        ErrorCode.BALLOON_AMOUNT_INVALID,
    ];

    public static VALIDATION_ERRORS: string[] = [
        ErrorCode.CAR_PRICE_INVALID_PATTERN,
        ErrorCode.CAR_PRICE_LESS_THEN_MINIMUM,
        ErrorCode.CAR_PRICE_EXCEEDED_MAX_VALUE,
        ErrorCode.DOWNPAYMENT_INVALID,
        ErrorCode.BALLOON_AMOUNT_INVALID,
    ];

}
