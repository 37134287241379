import { Error } from '../error';

export class LoginResponse {

  public token: string;
  public error: Error;


  constructor(data: any) {
    this.token = data.token;
    this.error = data.error;
  }

}
