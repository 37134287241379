import { Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';

@Injectable()
export class OfService {

    constructor(public formatter: FormattedStringToNumberPipe,
                public configService: ConfigService) {
    }


    generateOfUrl(request: CalculationRequest, pDealerId: string, pReturnUrl: string, pIsCreditCheckCertificateEntry: boolean, pBulkCalculationActive: boolean): string {
      const params: any = new Object();
        params.dealerid = Number(pDealerId);
        params.dealerURL = pReturnUrl;
        params.priceAmt = Number(this.formatter.transform(request.carPrice));
        params.downpaymentAmt = Number(this.formatter.transform(request.downpayment));
        params.ballonAmt = Number(this.formatter.transform(request.balloonAmount));
        params.term = Number(request.term);
        params.paymentProtectionInsurance = request.cliRequested;
        params.annualMileage = Number(request.mileage);
        params.isCreditCheckCertificateEntry = pIsCreditCheckCertificateEntry;
        params.bulkCalculationActive = pBulkCalculationActive;

        // base64 encode and remove '=' characters
        return this.configService.config.urls.of + window.btoa(JSON.stringify(params)).replace(new RegExp('=', 'g'), '');
    }

}
