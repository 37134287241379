import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalculationResponse } from '../../classes/responses/calculation-response';
import { EmailRequest } from '../../classes/requests/email-request';
import { CalculationRequest } from '../../classes/requests/calculation-request';
import { CalculationService } from '../../shared/calculation.service';
import { EmailService } from '../../shared/email.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmail } from '../../validators/email.validator';
import { TrackingService } from '../../shared/tracking.service';
import { EventType } from '../../constants/event-type';
import { TrackingEvent } from '../../classes/events/tracking-event';
import { ErrorService } from '../../shared/error.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toyrr-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss', '../../styles/styles.scss']
})
export class EmailFormComponent implements OnInit {

    emailForm: FormGroup;
    emailRequest: EmailRequest;
    calcResponse: CalculationResponse = new CalculationResponse();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    sending: boolean;
    showStatus: boolean;
    emailStatus: string;


    @Input() calculationRequest: CalculationRequest;

    constructor(
        public emailService: EmailService,
        public calculationService: CalculationService,
        public formBuilder: FormBuilder,
        public errorService: ErrorService,
        public trackingService: TrackingService,
        public translate: TranslateService
    ) {}


    ngOnInit() {
        this.emailForm = this.formBuilder.group({
            email: ['', [isEmail]]
        });
        this.emailRequest = new EmailRequest();
    }

    /**
     * Sendet Email mit den Eingaben und erneut berechneten Ergebnissen an die übergebene Adresse
     */
    onSubmit(): void {
        // Tracking
        this.trackingService.mailButtonEmitter.emit(new TrackingEvent(EventType.CLICK_MAIL));

        if (this.emailForm.invalid) {
            return;
        }
        this.sending = true;

        this.emailRequest.emailAddress = this.emailForm.value.email;
        this.emailRequest.locale = this.translate.getBrowserLang();

        this.emailService.sendMail(this.emailRequest, this.calculationRequest).subscribe(
            (success) => {
                this.emailStatus = 'email.sent';
            },
            (err) => this.emailStatus = 'email.error.general',

            () => {
                this.sending = false;
                this.showStatus = true;
            }
        );
    }

    onCancel(): void {
        this.cancel.emit();
    }

    isReady(): boolean {
        return !this.sending && !this.showStatus;
    }

}
