import { Error } from '../error';

export class CalculationResponse {

    public carPrice: number;
    public desiredCredit: number;
    public balloonAmount: number;
    public interestRateEffective: number;
    public error: Error;
    public totalFinanceAmount: number;
    public cliAmount: number;
    public interestRateNominal: number;
    public downpayment: number;


    /**
     * Factory method to create new CalculationRequest from existing data
     * @param data Already existing CalculationRequest data
     * @returns Instance of CalculationRequest with the given data
     */
    public static fromData(data: any): CalculationResponse {
        let res: CalculationResponse = new CalculationResponse();

        res.carPrice = data.carPrice;
        res.desiredCredit = data.desiredCredit;
        res.balloonAmount = data.balloonAmount;
        res.interestRateEffective = data.interestRateEffective;
        res.error = data.error;
        res.totalFinanceAmount = data.totalFinanceAmount;
        res.downpayment = data.downpayment;
        res.interestRateNominal = data.interestRateNominal;
        res.cliAmount = data.cliAmount;
        return res;
    }

}
