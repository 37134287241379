import { Error } from '../error';

export class CalculationResponse {

    public instalment;
    public cliAmount;
    public adminFee;
    public totalFinanceAmount;
    public interestRateEffective;
    public interestRateNominal;
    public netFinanceAmount;
    public error: Error;
    public balloonAmount?;

    /**
     * Factory method to create new CalculationRequest from existing data
     * @param data Already existing CalculationRequest data
     * @returns Instance of CalculationRequest with the given data
     */
    public static fromData(data): CalculationResponse {
        let res: CalculationResponse = new CalculationResponse();

        res.instalment = data.instalment;
        res.cliAmount = data.cliAmount;
        res.adminFee = data.adminFee;
        res.totalFinanceAmount = data.totalFinanceAmount;
        res.interestRateEffective = data.interestRateEffective;
        res.interestRateNominal = data.interestRateNominal;
        res.netFinanceAmount = data.netFinanceAmount;
        res.error = data.error;
        res.balloonAmount = data.balloonAmount;

        return res;
    }

}
