import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalculationResponse } from '../../classes/responses/calculation-response';
import { CalculationRequest } from '../../classes/requests/calculation-request';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmail } from '../../validators/email.validator';
import { EmailService } from '../../services/email.service';
import { EmailRequest } from '../../classes/requests/email-request';
import { BudgetrechnerComponent } from '../../budgetrechner.component';
import { TrackingService } from '../../services/tracking.service';
import { TrackingEvent } from '../../classes/events/tracking-event';
import { EventType } from '../../constants/event-type';

@Component({
  selector: 'toybr-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss', '../../styles/styles.scss']
})
export class EmailFormComponent implements OnInit {

  emailForm: FormGroup;
  emailRequest: EmailRequest;
  calcResponse: CalculationResponse = new CalculationResponse();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  sending: boolean;
  showStatus: boolean;
  emailStatus: string;


  @Input() calculationRequest: CalculationRequest;

  constructor(
    public emailService: EmailService,
    public formBuilder: FormBuilder,
    public trackingService: TrackingService
  ) {
  }


  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: ['', [isEmail]]
    });
    this.emailRequest = new EmailRequest();
  }

  /**
   * Sendet Email mit den Eingaben und erneut berechneten Ergebnissen an die übergebene Adresse
   */
  onSubmit(): void {
    if (this.emailForm.invalid) {
      return;
    }

    // Track email request
    this.trackingService.trackMailButtonClick(
      new TrackingEvent(EventType.CLICK_MAIL)
    );

    this.sending = true;

    this.emailRequest.emailAddress = this.emailForm.value.email;
    this.emailRequest.locale = BudgetrechnerComponent.getLanguage();

    this.emailService.sendMail(this.emailRequest, this.calculationRequest).subscribe(
      (success) => this.emailStatus = 'email.sent',
      (err) => this.emailStatus = 'email.error.general',
      () => {
        this.sending = false;
        this.showStatus = true;
      }
    );
  }

  onCancel(): void {
    this.cancel.emit();
  }

  isReady(): boolean {
    return !this.sending && !this.showStatus;
  }

}
