export class DataKey {

    public static PRODUCT_ID = 'productID';
    public static TERM = 'term';
    public static DOWNPAYMENT = 'downpayment';
    public static BALLOON_AMOUNT = 'balloonAmount';
    public static CLI_REQUESTED = 'cliRequested';
    public static CLI_AMOUNT = 'cliAmount';
    public static INTEREST_RATE_EFFECTIVE = 'interestRateEffective';
    public static INTEREST_RATE_NOMINAL = 'interestRateNominal';
    public static CAR_PRICE = 'carPrice';
    public static INSTALMENT = 'instalment';
    public static ADMIN_FEE = 'adminFee';
    public static TOTAL_FINANCE_AMOUNT = 'totalFinanceAmount';
    public static NET_FINANCE_AMOUNT = 'netFinanceAmount';

}
