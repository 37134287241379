<div id="email-form">
  <!-- Loader -->
  <div id="email-loader" class="loader" *ngIf="sending"></div>
  <div id="email-status" *ngIf="showStatus">
    <p>{{ emailStatus | translate }}</p>
  </div>
  <a *ngIf="showStatus" id="close-email-status" (click)="onCancel(); showStatus = false"><span
          class="icon icon-remove"></span></a>

  <!-- E-Mail Form -->
  <form
    class="email-form"
    [formGroup]="emailForm"
    (ngSubmit)="onSubmit()"
    [class.blur-on]="!isReady()"
    [class.blur-off]="isReady()">
    <label for="email">{{ 'app.mail.to' | translate }}
      <toybr-tooltip ttId="interestRateEffective">{{"app.email.tooltip" | translate}}</toybr-tooltip>
    </label>
    <input
      type="email"
      id="email"
      [class.error]="emailForm.invalid"
      placeholder="{{ 'email.placeholder' | translate }}"
      formControlName="email">
    <small class="errorMessage" *ngIf="emailForm.invalid && emailForm.dirty">{{ 'email.error.illegalAddress' | translate }}</small>

    <div class="buttons">
      <button type="button" class="white button button-small" (click)="onCancel()">{{ 'app.mail.cancel' | translate }}</button>
      <button type="submit" class="red button button-small" [disabled]="emailForm.invalid || !isReady()">{{ 'app.mail.send' | translate }}</button>
    </div>
  </form>
</div>
