<div class="tooltip-wrapper">
  <!-- Icon -->
  <span
    (mouseover)="onMouseEnter($event)"
    (mouseout)="onMouseLeave($event)"
    (click)="onClick()"
    class="tooltip-icon icon icon-info-sign">
  </span>

  <!-- Tooltip -->
  <div
    (click)="onClick()"
    class="tooltip-text"
    [class.visible]="show"
    [style.width]="width"
    [style.height]="height"
    [style.left]="left"
    [style.top]="top"
    [style.right]="right"
    [style.bottom]="bottom"
  >
    <!-- Content -->
    <ng-content></ng-content>
  </div>
</div>
