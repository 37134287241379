export class PriceUtils {

    static round(value: number, to: number): number {
        // Value doesn't have to be rounded
        if (value % to === 0) {
            return value;
        }

        return value + (value % to);
    }

}
