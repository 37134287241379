import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattedStringToNumber'
})
export class FormattedStringToNumberPipe implements PipeTransform {

  /**
   * Wandelt einen Formatierten String in eine Number um.
   * TODO: Erweiterung um ein Language-Tag
   */
  transform(value: any, args?: any): any {
    if (value == null) {
      return undefined;
    }

    const comma = /,/g;
    const dot = /\./g;
    value = String(value);
    value = value.replace(dot, '');
    value = value.replace(comma, '.');

    if (isNaN(value)) {
      return;
    }

    return value;
  }

}
