import { Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CalculationResponse } from '../classes/responses/calculation-response';
import { FormattedStringToNumberPipe } from '../pipes/formatted-string-to-number.pipe';
import { ConfigService } from './config.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PdfService {

  constructor(
    public formatter: FormattedStringToNumberPipe,
    public configService: ConfigService
  ) {
  }


  generatePdf(request: CalculationRequest, result: CalculationResponse, language: string): string {
    let params = new HttpParams();
    params = params.set('instalment', this.formatter.transform(request.instalment));
    params = params.set('downpayment', this.formatter.transform(request.downpayment));
    params = params.set('term', request.term.toString());
    params = params.set('balloonRequest', request.balloonRequest.toString());
    params = params.set('cliRequest', request.cliRequest.toString());
    params = params.set('interestRate', request.interestRate.toString());
    params = params.set('sessionID', request.sessionID.toString());
    params = params.set('customerID', request.customerID.toString());
    params = params.set('mileage', request.mileage.toString());
    params = params.set('language', language);

    return this.configService.config.urls.pdfBudget + '?' + params.toString();
  }

}
