import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subject } from 'rxjs';
import { CustomDecimalPipe } from '../../pipes/custom-decimal.pipe';
import { FormattedStringToNumberPipe } from '../../pipes/formatted-string-to-number.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const CURRENCY_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CurrencyInputComponent),
  multi: true
};

@Component({
  selector: 'toybr-currency-input',
  styleUrls: ['./currency-input.component.scss', '../../styles/styles.scss'],
  template: `
    <label class="input-label">{{label}}
      <toybr-tooltip [ttId]="ttId" *ngIf="!!tooltip">{{tooltip}}</toybr-tooltip>
    </label>
    <div class="currency-input-wrapper">
      <span class="currency-icon">€</span>
      <input #input type="text" class="currency-input" [disabled]="disable" (keyup)="keyUp($event, input.value)"
             (change)="writeValue(input.value)" [class.transparent]="transparent" [class.error]="hasError" [value]="rawValue">
    </div>
  `,
  providers: [
    CURRENCY_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class CurrencyInputComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() transparent: boolean;
  @Input() disable: boolean;
  @Input() tooltip: string;
  @Input() ttId: string;
  @Input() hasError: boolean;
  @Output()
  inputChangeReady: EventEmitter<any> = new EventEmitter<any>();

  inputReady: Subject<any> = new Subject();
  value: number;
  rawValue = '';

  onTouchedCallback: () => void = noop;
  onChangeCallback: (_: any) => void = noop;

  constructor(
    public customDecimal: CustomDecimalPipe,
    public formattedStringToNumber: FormattedStringToNumberPipe
  ) {
    // wartet 5ms auf keyups und emitted inputready
    this.inputChangeReady = this.inputReady.asObservable().pipe(
      debounceTime(2000),
      distinctUntilChanged()
    ) as any;
  }

  writeValue(obj: any): void {
    const unformatted = this.formattedStringToNumber.transform(obj);
    if (!unformatted) {
      return;
    }
    const formatted: string = this.customDecimal.transform(unformatted, 2, ',', '.', '');
    this.rawValue = formatted;
    this.onChangeCallback(formatted);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /**
   * keyup event handling
   */
  keyUp(event: any, obj: any) {
    // console.log("TasteNr: " + event.code + " oder " + event.which);
    if (event.which === 9 || // TAB
      event.which === 8 || // Backspace
      event.which === 27 || // ESC
      event.which === 37 || // ARROWS
      event.which === 38 || // ARROWS
      event.which === 39 || // ARROWS
      event.which === 40 || // ARROWS
      event.which === 46 // ENTF
    ) {
      return;
    }
    this.rawValue = obj;
    this.onChangeCallback(obj);
    // console.log("Keypressed " + this.label + " new Value: "+ obj );
    this.inputReady.next(event);
  }
}
