import { Injectable } from '@angular/core';
import { Error } from '../classes/error';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorService {

  _errors: Error[] = [];


  constructor(
    public translate: TranslateService
  ) {
  }


  /**
   * Erstellt einen neuen Fehler
   * @param err Der Fehler, welcher erstellt werden soll
   */
  error(err: Error): void {
    if (this.hasError(err.errorCode)) {
      return;
    }

    this.errors.push(err);
  }

  /**
   * Entfernt vorhandene / übergebene Fehlermeldungen
   */
  okAll(errTypes: string[]) {
    this.errors = this.errors.filter((err) => {
      return errTypes.indexOf(err.errorCode) === -1;
    });
  }

  /**
   * Prüft ob ein Fehler eines gegebenen Typs vorhanden ist
   * @param type Der Fehlertyp
   * @see constants/error-type.ts
   * @returns True wenn der Fehler vorhandenen ist, false, wenn nicht
   */
  hasError(type: string): boolean {
    for (const err of this.errors) {
      if (err.errorCode === type) {
        return true;
      }
    }
    return false;
  }

  /**
   * Entfernt alle vorhandenen Fehler
   */
  clearErrors(): void {
    this._errors = [];
  }


  // Getters / Setters

  get errors(): Error[] {
    return this._errors;
  }

  set errors(errors: Error[]) {
    this._errors = errors;
  }


  connectionError(): Error {
    return new Error(
      'WS_CONNECTION_ERROR',
      this.translate.instant('app.error.connect')
    );
  }

  generalError(): Error {
    return new Error(
      'WS_GENERAL_ERROR',
      this.translate.instant('app.error.general')
    );
  }

}
