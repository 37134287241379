export class EventType {

    public static VIEW_PAGE = 'VIEW_PAGE';
    public static INITIAL_CALCULATION = 'INITIAL_CALCULATION';
    public static CALCULATION = 'CALCULATION';
    public static CALCULATION_RESULT = 'CALCULATION_RESULT';
    public static CLICK_PRINT = 'CLICK_PRINT';
    public static CLICK_MAIL = 'CLICK_MAIL';
    public static CLICK_OF = 'CLICK_OF';
    public static TOOLTIP_SHOW = 'TOOLTIP_SHOW';
    public static TOOLTIP_HIDE = 'TOOLTIP_HIDE';
    public static VALUE_CHANGE = 'VALUE_CHANGE';

}
