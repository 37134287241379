import { ElementRef, Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';

@Injectable()
export class AttributeConfigService {

    constructor() {
    }


    /**
     * Prüft ob mindestens ein Konfigurationsattribut gesetzt ist.
     * @param elRef Das Element, das geprüft werden soll
     * @returns true, falls ein Attribut gesetzt ist, false, falls nicht
     */
    isAnyAttributeSet(elRef: ElementRef): boolean {
        return (
            elRef.nativeElement.hasAttribute('interest') ||
            elRef.nativeElement.hasAttribute('term') ||
            elRef.nativeElement.hasAttribute('downpayment') ||
            elRef.nativeElement.hasAttribute('balloon')
        );
    }

    /**
     * Übernimmt die über Parameter konfigurierbaren Werte auf einen CalculationRequest.
     * @param req Der Request, welcher angepasst werden soll
     * @returns Der angepasste Request
     */
    applyConfigToCalculationRequest(req: CalculationRequest, elRef: ElementRef): CalculationRequest {
      const request: CalculationRequest = req;

        // Zinssatz
      const interest: number = elRef.nativeElement.getAttribute('interest');
        if (interest != null) {
            request.interestRate = interest;
        }

        // Laufzeit
      const term: number = elRef.nativeElement.getAttribute('term');
        if (term != null) {
            request.term = term;
        }

        // Anzahlung
      const downpayment: number = elRef.nativeElement.getAttribute('downpayment');
        if (downpayment != null) {
            request.downpayment = (request.carPrice / 100 * downpayment).toFixed(0);
        }

        // Schlussrate
      const balloonAmount: number = elRef.nativeElement.getAttribute('balloon');
        if (balloonAmount != null) {
            request.balloonAmount = (request.carPrice / 100 * balloonAmount).toFixed(0);
        }

        return request;
    }

}
