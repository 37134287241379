import { Injectable } from '@angular/core';
import { CalculationRequest } from '../classes/requests/calculation-request';
import { CookieHandleService } from './cookie-handle.service';
import { ConfigService } from './config.service';

@Injectable()
export class PublicRatenrechnerService {

  /*
    Public Service for OnlineFinanzierung
   */
  constructor(public cookieHandleService: CookieHandleService,
              public configService: ConfigService) {
  }

  setRequestCookie(request: CalculationRequest, bulkCalculationActive: boolean) {
    let name;
    let duration;
    if (bulkCalculationActive) {
      name = this.configService.config.bulkReqCookieName;
      duration = this.configService.config.bulkReqCookieDuration;
    }else {
      name = this.configService.config.reqCookieName;
      duration = this.configService.config.reqCookieDuration;
    }

    this.cookieHandleService.saveCookie(name, CalculationRequest.forCache(request), duration)
  }

}
