import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorBoxComponent } from './components/error-box/error-box.component';
import { CustomDecimalPipe } from './pipes/custom-decimal.pipe';
import { ParentScannerService } from './shared/parent-scanner.service';
import { CalculationService } from './shared/calculation.service';
import { ErrorService } from './shared/error.service';
import { CalculationFormComponent } from './components/calculation-form/calculation-form.component';
import { BulkCalculationFormComponent } from './components/bulk-calculation-form/bulk-calculation-form.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { AuthorizationService } from './shared/authorization.service';
import { CalculationOutputFormComponent } from './components/calculation-output-form/calculation-output-form.component';
import { BulkCalculationOutputFormComponent } from './components/bulk-calculation-output-form/bulk-calculation-output-form.component';
import { EmailService } from './shared/email.service';
import { AttributeConfigService } from './shared/attribute-config.service';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { EmailFormComponent } from './components/email-form/email-form.component';
import { ResultSelectComponent } from './components/result-select/result-select.component';
import { PdfService } from './shared/pdf.service';
import { OfService } from './shared/of.service';
import { TrackingService } from './shared/tracking.service';
import { CookieHandleService } from './shared/cookie-handle.service';
import { FormattedStringToNumberPipe } from './pipes/formatted-string-to-number.pipe';
import { MatchHeightDirective } from './directives/match-height.directive';
import { RatenrechnerComponent } from './ratenrechner.component';
import { RatenrechnerConfig } from './config/ratenrechner-config';
import { ConfigService } from './shared/config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { CookieModule } from 'ngx-cookie';
import { JwtHelperService } from './shared/jwt.service';
import { CookieHintComponent } from './components/cookie-hint/cookie-hint.component';
import { PublicRatenrechnerService } from './shared/public-ratenrechner.service';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, ConfigService]
      }
    }),
    CookieModule.forChild()
  ],
  declarations: [
    RatenrechnerComponent,
    CalculationFormComponent,
    BulkCalculationFormComponent,
    ErrorBoxComponent,
    CustomDecimalPipe,
    CurrencyInputComponent,
    CalculationOutputFormComponent,
    BulkCalculationOutputFormComponent,
    TooltipComponent,
    EmailFormComponent,
    ResultSelectComponent,
    CookieHintComponent,
    FormattedStringToNumberPipe,
    MatchHeightDirective
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  ],
  exports: [
    RatenrechnerComponent,
    CalculationFormComponent,
    BulkCalculationFormComponent,
    ErrorBoxComponent,
    CustomDecimalPipe,
    CurrencyInputComponent,
    CalculationOutputFormComponent,
    BulkCalculationOutputFormComponent,
    TooltipComponent,
    EmailFormComponent,
    CookieHintComponent,
    FormattedStringToNumberPipe,
    MatchHeightDirective
  ],
  bootstrap: [RatenrechnerComponent]
})
export class RatenrechnerModule {

  static forRoot(config?: RatenrechnerConfig): ModuleWithProviders<RatenrechnerModule> {
    return {
      ngModule: RatenrechnerModule,
      providers: [
        {
          provide: RatenrechnerConfig,
          useValue: config
        },
        {
          provide: ConfigService,
          deps: [RatenrechnerConfig],
          useFactory: configServiceFactory
        },
        {provide: LOCALE_ID, useValue: 'de-DE'},
        CalculationService,
        EmailService,
        AuthorizationService,
        ErrorService,
        ParentScannerService,
        AttributeConfigService,
        CustomDecimalPipe,
        FormattedStringToNumberPipe,
        CookieHandleService,
        PdfService,
        OfService,
        TrackingService,
        JwtHelperService,
        PublicRatenrechnerService
      ]
    };
  }

}

// <editor-fold> Exported functions
export function configServiceFactory(config?: RatenrechnerConfig) {
  return new ConfigService(config);
}

export function createTranslateLoader(http: HttpClient, configService: ConfigService) {
  if (configService.config.translateLoaderFactory) {
    let output: TranslateHttpLoader | Observable<TranslateHttpLoader> = configService.config.translateLoaderFactory(http);

    if (output instanceof Observable) {
      output.subscribe((data) => {
        output = data;
      }).unsubscribe();
    }

    return output;
  } else {
    // return new TranslateStrapiLoader(http, configService.config.urls.i18n);
    return new TranslateHttpLoader(http, configService.config.urls.i18n + '/', '.json');
  }
}

export function createTokenGetter() {
  return localStorage.getItem('token');
}

// </editor-fold>
