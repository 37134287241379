import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {

    /**
     * Nimmt einen Wert entgegen, formatiert ihn und gibt ihn wieder zurück.
     * @param value Der Wert, der formatiert werden soll
     * @param decimalPlaces Die Anzahl der gewünschten Nachkommastellen
     * @param thousandSep Zeichen, das als Tausender-Trennzeichen dienen soll
     * @param decimalSep Zeichen, das als Komma dienen soll
     * @param endCharacter Währungszeichen, das sich am Ende befindet
     * @returns Formatierter Währungs-String
     */
    transform(value: any, decimalPlaces: number, thousandSep: string, decimalSep: string, endCharacter?: string): string {
        if (value == null) {
            return undefined;
        }

        if (value === '') {
            return '';
        }

        if (typeof value === 'string' && value.indexOf(thousandSep) !== -1) {
            return value;
        }

        let decPlaces = isNaN(decimalPlaces = Math.abs(decimalPlaces)) ? 2 : decimalPlaces,
            d = thousandSep === undefined ? '.' : thousandSep,
            t = decimalSep === undefined ? ',' : decimalSep,
            s = value < 0 ? '-' : '',
            i = parseInt(value = Math.abs(value || 0).toFixed(decimalPlaces), 10) + '',
            j = (i.length) > 3 ? i.length % 3 : 0;

        return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t)
            + (decPlaces ? d + Math.abs(value - parseInt(i, 10)).toFixed(decPlaces).slice(2) : '')
            + (endCharacter != null ? endCharacter : '');
    }

}
