import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ParentScannerService } from './shared/parent-scanner.service';
import { CalculationService } from './shared/calculation.service';
import { AuthorizationService } from './shared/authorization.service';
import { ErrorService } from './shared/error.service';
import { Error } from './classes/error';
import { LoginResponse } from './classes/responses/login-response';
import { TrackingService } from './shared/tracking.service';
import { TrackingEvent } from './classes/events/tracking-event';
import { EventType } from './constants/event-type';
import { CalculationRequest } from './classes/requests/calculation-request';
import { ConfigService } from './shared/config.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeEvent } from './classes/events/change-event';
import { CalculationResponse } from './classes/responses/calculation-response';
import { ErrorCode } from './constants/error-code';
import { CalculationFormComponent } from './components/calculation-form/calculation-form.component';
import { BulkCalculationFormComponent } from './components/bulk-calculation-form/bulk-calculation-form.component';
import { Product } from './classes/requests/product';
import { ProductService } from './shared/product.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'toyrr-ratenrechner',
  templateUrl: './ratenrechner.component.html',
  styleUrls: ['./ratenrechner.component.scss', './styles/styles.scss']
})
export class RatenrechnerComponent implements OnInit {

  public static serverOnline = true;
  priceDetectedRequest: CalculationRequest;

  @Input() defaultRequest: CalculationRequest;

  @Input() autodetect: boolean;
  @Input() autodetectId: string;
  @Input() autodetectClass: string;
  @Input() autodetectAttr: string;

  @Input() interest: string;
  @Input() term: string;
  @Input() downpayment: string;
  @Input() balloon: string;
  @Input() mileage: string;

  @Input() dealerId: string;
  @Input() returnUrl: string;
  @Input() certificateOnly: boolean;
  @Input() sizeClass: string;

  @Input() bulkCalculationActive = false;
  @Input() isOnlineFinanzierung = false;

  @Output() inputChange: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();
  @Output() requestChange: EventEmitter<CalculationRequest> = new EventEmitter<CalculationRequest>();
  @Output() calculate: EventEmitter<CalculationResponse> = new EventEmitter<CalculationResponse>();
  @Output() calculationError: EventEmitter<Error> = new EventEmitter<Error>();
  @Output() validationError: EventEmitter<Error> = new EventEmitter<Error>();

  @ViewChild(CalculationFormComponent) calculationForm: CalculationFormComponent;
  @ViewChild(BulkCalculationFormComponent) bulkCalculationForm: BulkCalculationFormComponent;

  /**
   * The calculation forms will only be initialized after this has been set to true
   */
  ready: boolean;

  public static setServerOnline(online: boolean) {
    RatenrechnerComponent.serverOnline = online;
  }

  constructor(
    public parentScannerService: ParentScannerService,
    public calculationService: CalculationService,
    public productService: ProductService,
    public authorizationService: AuthorizationService,
    public errorService: ErrorService,
    public translate: TranslateService,
    public configService: ConfigService,
    public trackingService: TrackingService
  ) {
    registerLocaleData(localeDe);
  }


  ngOnInit() {
    this.isPriceDetected();
    this.configureLanguage();

    // set dealerID in config
    if (this.dealerId) {
      this.configService.config.dealerID = this.dealerId;
    }

    // Authentifizierungs-Token von Webservice holen und initiale Berechnung ausführen
    this.authorizationService.authorize()
      .subscribe(
        (success: LoginResponse) => {
          this.setProduct();
          /*          this._setupRequestChangedEmitter();*/
        },
        (err) => {
          // Authentifizierung fehlgeschlagen
          RatenrechnerComponent.setServerOnline(false);
        }
      );

    // Tracking
    this.trackingService.pageViewEmitter.emit(new TrackingEvent(EventType.VIEW_PAGE));

    // Error
    this.errorService.errorOccurred.subscribe((error) => this._onError(error));

    // Subscribe to calculation errors and emit them through the calculationError EventEmitter
    this.errorService.subscribe(ErrorCode.VALIDATION_ERRORS)
      .subscribe((error) => this.validationError.emit(error));

    // Subscribe to visibility changes
    this._onVisibilityChange(document.getElementById('calc-title'))
      .subscribe((visibility: boolean) => this.trackingService.onVisibilityChange(visibility));
  }

  /**
   * Get the ProductID and set it for the CalculationRequest
   */
  setProduct() {
    this.calculationService.getProducts()
      .subscribe((product: Product) => {
        if (!product.defaultAnnualMileage || product.defaultAnnualMileage <= 0) {
          product.defaultAnnualMileage = 20000;
        }
        this.productService.product = product;
      }, (err: any) => {
        this.productService.product = null;
      }, () => {
        if (this.productService.product) {
          this._setupRequestChangedEmitter();
        } else {
          this._setupFirstCalculation();
        }
      });
  }

  /**
   * Check if a car price can be detected
   */
  private isPriceDetected(): void {
    if (!this.configService.config.isPriceDetected) {
      this._detectPrice().subscribe((price: any) => {
        if (price) {
          /*               this.calculationService.isPriceDetected = true;*/
          this.configService.config.isPriceDetected = true;
        } else {
          this.configService.config.isPriceDetected = false;
          this.configService.config.wasEmptyLoaded = true;
        }
      }, (error: any) => {
        /*            this.calculationService.isPriceDetected = false;*/
        this.configService.config.isPriceDetected = false;
        this.configService.config.wasEmptyLoaded = true;
      });
    }
  }

  configureLanguage() {
    let language = this.translate.getBrowserLang();

    // Load default language package
    if (!this.configService.config.isPriceDetected && !this.defaultRequest) {
      language += '_b0';
    }

    this.translate.setDefaultLang(this.configService.config.defaultLanguage);
    this.translate.use(language);

    // console.log('Using language', this.translate.currentLang);
  }

  _setupRequestChangedEmitter() {
    if (!this.productService.product) {
      return;
    }

    // Änderungen an CalculationRequest
    this.calculationService.requestChanged.subscribe((request: CalculationRequest) => {
      this.requestChange.emit(request);
    });

    this._setupFirstCalculation();
  }

  _setupFirstCalculation() {
    if (!this.defaultRequest) {
      this.defaultRequest = new CalculationRequest();
    }

    if (this.interest) {
      this.defaultRequest.interestRate = Number(this.interest);
    }

    if (this.term) {
      this.defaultRequest.term = Number(this.term);
    }

    if (this.downpayment) {
      this.defaultRequest.downpayment = Number(this.downpayment);
    }

    if (this.balloon) {
      this.defaultRequest.balloonAmount = Number(this.balloon);
      this.defaultRequest.balloonAmountRequested = true;
    }

    if (this.mileage) {
      this.defaultRequest.mileage = Number(this.mileage);
      this.defaultRequest.balloonAmountRequested = true;
    }

    this.ready = true;

    console.log('defaultRequest', this.defaultRequest);
  }


  /**
   * Sucht einen Fahrzeugpreis auf der Elternseite
   * anhand von gegebenen Parametern.
   */
  _detectPrice(): Observable<number> {
    return new Observable<number>((observer) => {
      // Price Detection nicht aktiviert
      if (!this.autodetect) {
        observer.next(null);
        observer.complete();
        return;
      }

      // Startpreis finden und übernehmen
      const id = this.autodetectId || 'price-quantity';
      const clazz = this.autodetectClass || 'price-quantity';
      const attribute = this.autodetectAttr || 'price';

      // True und False geben an, ob die deutsche Notation verwendet wird
      this.parentScannerService.findPrice(id, clazz, attribute, '€', true).subscribe(
        (price) => {
          // console.log('[AutoDetect] Preis gefunden: ' + price);
          // this.calculationService.lastRequest.carPrice = price;
          // this.calculationService.getCalculationResult(this.calculationService.lastRequest).subscribe();#
          this.configService.config.isPriceDetected = true;
          observer.next(price);
          observer.complete();
        },
        (error) => {
          // console.log('[AutoDetect] Keinen Preis gefunden.');
          observer.next(null);
          observer.complete();
        }
      );
    });
  }


  /**
   * Check whether a specific element is visible
   * @param el The the element to check
   */
  _isElementInViewPort(el: any) {

    if (!el) {
      return;
    }

    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Subscribe to visibility changes of a specific element
   * @param el The element to subscribe to
   */
  _onVisibilityChange(el): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const cb = (event) => observer.next(this._isElementInViewPort(el));

      window.addEventListener('DOMContentLoaded', cb);
      window.addEventListener('load', cb);
      window.addEventListener('resize', cb);
      window.addEventListener('scroll', cb);

      cb(null);
    });
  }

  _onCalcChange(event: ChangeEvent): void {
    // console.log("_onCalcChange");
    // console.log(event);
    this.inputChange.emit(event);
  }

  _onCalculate(response: CalculationResponse): void {
    if (!this.configService.config.isPriceDetected) {
      this.configService.config.isPriceDetected = true;
    }
    this.calculate.emit(response);
  }

  _onError(err: Error): void {
    const calcResponse = new CalculationResponse();
    calcResponse.error = err;

    this.calculate.emit(calcResponse);
    this.calculationError.emit(err);
  }


  // API Methoden

  isServerOnline(): boolean {
    return RatenrechnerComponent.serverOnline;
  }

  triggerRecalculation(): void {
    // console.log("Called refreshView");
    if (this.bulkCalculationActive) {
      this.bulkCalculationForm.forceRecalculation();
    } else {
      this.calculationForm.forceRecalculation();
    }
  }

  triggerCalculation(): Observable<CalculationResponse> {
    return this.calculationService
      .getCalculationResult(this.calculationService._lastRequest);
  }

  customCalculation(request: CalculationRequest): Observable<CalculationResponse> {
    return this.calculationService
      .getCalculationResult(request);
  }

}
