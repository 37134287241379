import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CookieHandleService } from './services/cookie-handle.service';
import { EmailService } from './services/email.service';
import { PdfService } from './services/pdf.service';
import { ErrorService } from './services/error.service';
import { CalculationService } from './services/calculation.service';
import { AuthorizationService } from './services/authorization.service';
import { LoginResponse } from './classes/responses/login-response';
import { CalculationRequest } from './classes/requests/calculation-request';
import { ConfigService } from './services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackingEvent } from './classes/events/tracking-event';
import { EventType } from './constants/event-type';
import { TrackingService } from './services/tracking.service';
import { Observable, Subscriber } from 'rxjs';

@Component({
  selector: 'toybr-budgetrechner',
  templateUrl: './budgetrechner.component.html',
  styleUrls: ['./budgetrechner.component.scss', './styles/styles.scss']
})
export class BudgetrechnerComponent implements OnInit {

  static serverOnline = true;

  @Input() showSearchButton: boolean;
  @Input() priceInterval: number;
  @Input() priceBasis: number;

  @Input() fontSize: number;


  /**
   * Gibt die aktuelle Sprache des Browsers zurück.
   * Die Sprache wird bei Bedarf von dem Format
   * 'xx-XX' in das Format 'xx' formatiert.
   * @returns Die aktuelle Sprache des Browsers
   */
  public static getLanguage(): string {
    let language = 'en';

    if (navigator['languages']) {
      language = navigator['languages'][0];
    } else if (navigator.language) {
      language = navigator.language;
    } else if (navigator['userLanguage']) { // IE
      language = navigator['userLanguage'];
    }

    if (language.indexOf('-') !== -1) {
      language = language.split('-')[0];
    }

    return language;
  }

  public static setServerOnline(online: boolean) {
    BudgetrechnerComponent.serverOnline = online;
  }


  constructor(
    public elRef: ElementRef,
    public calculationService: CalculationService,
    public authorizationService: AuthorizationService,
    public errorService: ErrorService,
    public emailService: EmailService,
    public pdfService: PdfService,
    public translate: TranslateService,
    public cookieHandleService: CookieHandleService,
    public configService: ConfigService,
    public trackingService: TrackingService
  ) {
    translate.setDefaultLang(configService.config.defaultLanguage);
    translate.use(BudgetrechnerComponent.getLanguage());
  }

  ngOnInit(): any {
    // Track page view
    this.trackingService.trackPageView(new TrackingEvent(EventType.VIEW_PAGE));

    // Authentifizierungs-Token von Webservice holen und initiale Berechnung ausführen
    this.authorizationService.authorize()
      .subscribe(
        (success: LoginResponse) => {
          // Calculation mit Cookie-Request
          if (this.cookieHandleService.isPresent(this.configService.config.cookieName)) {
            const cookieRequest: CalculationRequest = this.cookieHandleService
              .loadCookieAsCalculationRequest(this.configService.config.cookieName);
            this.calculationService.getCalculationResult(cookieRequest).subscribe();
          } else {
            // Berechnung mit vorgebenen Werten ausführen
            const defaultRequest: CalculationRequest = CalculationRequest.default();
            this.calculationService.getCalculationResult(defaultRequest).subscribe();
          }
        }
      );

    // Subscribe to visibility changes
    this._onVisibilityChange(document.getElementById('calc-title'))
      .subscribe((visibility: boolean) => this.trackingService.trackVisibility(visibility));
  }

  get serverOnline() {
    return BudgetrechnerComponent.serverOnline;
  }

  generatePdf(): void {
    const url = this.pdfService.generatePdf(
      this.calculationService.lastRequest,
      this.calculationService.lastResponse,
      BudgetrechnerComponent.getLanguage()
    );
    window.open(url, '_blank');
  }


  /**
   * Check whether a specific element is visible
   * @param el The the element to check
   */
  _isElementInViewPort(el: any) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Subscribe to visibility changes of a specific element
   * @param el The element to subscribe to
   */
  _onVisibilityChange(el): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const cb = (event) => observer.next(this._isElementInViewPort(el));

      window.addEventListener('DOMContentLoaded', cb);
      window.addEventListener('load', cb);
      window.addEventListener('resize', cb);
      window.addEventListener('scroll', cb);

      cb(null);
    });
  }

}
