<div id="rr-container" *ngIf="ready">
  <toyrr-calculation-form *ngIf="!bulkCalculationActive"
    [defaultRequest]="defaultRequest"
    [class]="sizeClass"
    [servOn]="isServerOnline()"
    [dealerId]="dealerId"
    [returnUrl]="returnUrl"
    [certificateOnly]="certificateOnly"
    [isOnlineFinanzierung]="isOnlineFinanzierung"
    (calcChange)="_onCalcChange($event)"
    (calculate)="_onCalculate($event)"
  ></toyrr-calculation-form>
  <toyrr-bulk-calculation-form *ngIf="bulkCalculationActive"
     [defaultRequest]="defaultRequest"
     [class]="sizeClass"
     [servOn]="isServerOnline()"
     [dealerId]="dealerId"
     [returnUrl]="returnUrl"
     [certificateOnly]="certificateOnly"
     [isOnlineFinanzierung]="isOnlineFinanzierung"
     (calcChange)="_onCalcChange($event)"
     (calculate)="_onCalculate($event)"
  ></toyrr-bulk-calculation-form>
  <toyrr-cookie-hint></toyrr-cookie-hint>
</div>
