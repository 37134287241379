<form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
  <div class="modal blur-off" *ngIf="!servOn">
    <span> Service steht zur Zeit nicht zur Verfügung / Service is temporarily unavailable</span>
  </div>
  <div [class.serverOffline]="!servOn">
    <div class="mainsection">
      <div class="input rrcontainers input-rr-container" id="rr-input">
        <!-- Überschrift -->
        <h2 id="calc-title">{{"app.financing" | translate}}</h2>

        <!-- Fahrzeugpreis -->
        <div class="calcform-header">
          <toyrr-currency-input
            formControlName="carPrice"
            [label]="'calculation-form.carPrice' | translate"
            [(ngModel)]="request.carPrice"
            [hasError]="!inputForm.get('carPrice').valid"
            (inputChangeReady)="onSubmit(); onCarPriceChange();"
            (blur)="onBlur('carPrice', $event)"
            (change)="onChange('carPrice', $event)">
          </toyrr-currency-input>
        </div>

        <div class="input-group abstand-container abstand-container-1">
          <!-- Anzahlung -->
          <div class="input-element abstand-box">
            <toyrr-currency-input
              formControlName="downpayment"
              [tooltip]="'calculation-form.downpayment.tooltip' | translate"
              ttId="downpayment"
              [label]="'calculation-form.downpayment' | translate"
              [(ngModel)]="request.downpayment"
              [hasError]="!inputForm.get('downpayment').valid"
              (inputChangeReady)="onSubmit()"
              (blur)="onBlur('downpayment', $event)"
              (change)="onChange('downpayment', $event)">
            </toyrr-currency-input>
          </div>

          <!-- Einmalige Schlussrate -->
          <div class="input-element abstand-box" *ngIf="request.balloonAmountRequested">

            <!-- Laufleistung -->
            <label class="input-label">{{"calculation-form.mileage" | translate}}</label>
            <div class="select-wrapper">
              <select [(ngModel)]="request.mileage"
                      (change)="onMileageChange($event.target.value); onSubmit(); onChange('mileage', $event.target.value);"
                      class="input-element" formControlName="mileage">
                <option *ngFor="let option of possibleMileage" [value]="option.value"
                        [attr.selected]="inputForm.get('mileage').value == option.value ? true : null">
                  {{option.key}} km
                </option>
              </select>
            </div>

            <!--
            <toyrr-currency-input
                      formControlName="balloonAmount"
                      [disable]="isBalloonAmountDisabled"
                      [label]="'calculation-form.balloonAmount' | translate"
                      [(ngModel)]="request.balloonAmount"
                      [hasError]="!inputForm.get('balloonAmount').valid"
                      (inputChangeReady)="onSubmit()"
                      (change)="onChange('balloonAmount', $event)">
              </toyrr-currency-input>-->
          </div>
        </div>

        <div class="input-group-reverse abstand-container abstand-container-2">
          <!-- Restschuldversicherung -->
          <!-- RSV ist fix deaktiviert (14.05.2020) -->
          <div class="input-element abstand-box" style="display: none">
            <input type="checkbox" class="checkbox" id="rsv" formControlName="cliRequested"
                   [(ngModel)]="request.cliRequested"
                   (change)="onSubmit(); onChange('cliRequested', $event.target.value);">
            <label for="rsv">{{"calculation-form.cliRequested" | translate}}
              <toyrr-tooltip ttId="cliRequested">{{"calculation-form.cliRequested.tooltip" | translate}}</toyrr-tooltip>
            </label>
          </div>
          <!-- Mit Schlussrate finanzieren -->
          <div class="input-element abstand-box-2">
            <input type="checkbox" id="mit-schlussrate" class="checkbox"
                   formControlName="balloonAmountRequested"
                   (change)="onChangeBalloonAmountRequested()"
                   [(ngModel)]="request.balloonAmountRequested">
            <label for="mit-schlussrate">{{"calculation-form.balloonAmountRequested" | translate}}
              <toyrr-tooltip
                ttId="balloonAmountRequested">{{"calculation-form.balloonAmountRequested.tooltip" | translate}}</toyrr-tooltip>
            </label>
          </div>
        </div>


        <!-- KM-Auswahl -->
        <!--
        <div class="input-group-reverse abstand-container abstand-container-3" *ngIf="request.balloonAmountRequested">
            <div class="input-element abstand-box">
                <label class="select-label">{{"calculation-form.mileage" | translate}}</label>
                <select [(ngModel)]="request.mileage" (change)="onMileageChange($event.target.value); onSubmit(); onChange('mileage', $event.target.value);" class="input-element" formControlName="mileage">
                    <option *ngFor="let option of possibleMileage" [value]="option.value" [attr.selected]="inputForm.get('mileage').value == option.value ? true : null">
                        {{option.key}} km
                    </option>
                </select>
            </div>
        </div>
        -->

        <div class="laufzeit-group-wrapper abstand-container-4">
          <!-- Laufzeit in Monaten -->
          <label for="laufzeit-group">{{"calculation-form.term" | translate}}</label>
          <div id="laufzeit-group" class="flex-container">
            <div *ngFor="let t of possibleTerms; let i = index" class="laufzeit-group">
              <input #term type="radio" formControlName="term"
                     (change)="onTermChange(t)"
                     [id]="'term-' + t"
                     [value]="t" [(ngModel)]="request.term">
              <label [for]="'term-' + t">{{t}}</label>
            </div>
          </div>
        </div>

        <!-- Finanzierung berechnen -->
        <div class="calc-and-error">

          <!-- Fehlermeldungen -->
          <div id="errorbox-container" *ngIf="errorService.errors.length > 0 && errorService.latest != null">
                    <span>
                        <toyrr-error-box [error]="errorService.latest"></toyrr-error-box>
                    </span>
          </div>
        </div>
      </div>

      <!-- Output -->
      <toyrr-calculation-output-form [ngClass]="{ 'removeTableView': hideElementActive() }"
                                     [inputFormValid]="inputForm.valid"
                                     class="output rrcontainers output-rr-container"
                                     id="rr-output"
                                     [loading]="showCalcLoader"
                                     (calculate)="onCalculate($event)"></toyrr-calculation-output-form>
      <!-- Only show if dealerID is present -->
      <div *ngIf="dealerId && !hideElementActive() && !isOnlineFinanzierung" id="requestbox">
        <div *ngIf="!certificateOnly" class="requestButton" (click)="goToOnlineFinanzierung(false)"
             [ngClass]="{disabled: !inputForm.valid || errorService.errors.length !==0}">
          {{"app.request.button" | translate}}
        </div>
        <div *ngIf="certificateOnly" class="requestButton" (click)="goToOnlineFinanzierung(true)"
             [ngClass]="{disabled: !inputForm.valid || errorService.errors.length !==0}">
          {{"app.creditCheck.button" | translate}}
        </div>
      </div>

      <!-- Legal Info -->
      <div id="legal-info">
        <p id="star">*</p>
        <div>
          <span [innerHTML]="'app.legalInfo' | translate"></span><br/>
          <span *ngIf="(this.configService.config.analyticsTrackingId || this.configService.config.googleTagMangerCode)"
                class="text-left legal-text-left" [innerHTML]="'app.legalInfoTracking' | translate"></span>
          <a *ngIf="(this.configService.config.analyticsTrackingId || this.configService.config.googleTagMangerCode)"
             class="text-left legal-text-left" href="javascript:window.toyota.ratenrechner.disableTracking()">Analytics
            Opt-Out</a>
        </div>
      </div>
    </div>
  </div>
</form>
